import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  Button,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";
import GroupIcon from "@mui/icons-material/Group";
import { useNavigate } from "react-router-dom";
import { store } from "../../redux/store";
import SettingsIcon from "@mui/icons-material/Settings";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSavedUser } from "../../redux/slices/userSlice";
import { setBlockRedirect } from "../../redux/slices/errorSlice";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SpeedIcon from "@mui/icons-material/Speed";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import LockIcon from "@mui/icons-material/Lock";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import PercentIcon from "@mui/icons-material/Percent";
import { useParams } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import theme from "../../theme";
import DeleteButton from "../../utils/DeleteButton";
import ModalDialog from "../meters/ModalDialog";
import { setMessage } from "../../redux/slices/snackbarSlice";

const PolicyShowCard = ({ data }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const now = new Date();
  const [abortModal, setAbortModal] = useState(false);
  const [getPolicyShow] = store.useLazyGetPolicyShowQuery();
  const [abortPolicy, { isSuccess: abortSuccess }] =
    store.useAbortPolicyMutation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getUser] = store.useLazyGetUserShowQuery();
  const startDate = new Date(data?.start_date);
  const policyShow = useSelector((state) => state?.policySlice.policyShow);
  const endDate = new Date(data?.end_date);
  const interruptedDate = data?.interrupted
    ? new Date(data?.interrupted)
    : null;

  const isActive = now >= startDate && now <= endDate;
  const isPassed = now > endDate;
  const userShow = useSelector((state) => state?.userSlice?.userShow);
  const user = `${userShow?.attributes?.first_name} ${userShow?.attributes?.last_name}`;

  useEffect(() => {
    if (data) {
      getUser(data?.user_id);
    }
  }, [data]);

  useEffect(() => {
    dispatch(setBlockRedirect(true));
    return () => {
      dispatch(setBlockRedirect(false));
    };
  }, []);

  useEffect(() => {
    if (userShow) {
      dispatch(
        setSavedUser({
          label: `${userShow?.attributes?.first_name} ${userShow?.attributes?.last_name}`,
          value: userShow?.id,
        })
      );
    }
  }, [userShow]);

  const closeAbortModal = () => {
    setAbortModal(false);
  };

  useEffect(() => {
    if (abortSuccess) {
      dispatch(setMessage(t("abort_policy_success")));
      getPolicyShow(id);
      setAbortModal(false);
    }
  }, [abortSuccess]);

  return (
    <Box>
      <ModalDialog
        open={abortModal}
        title={t("abort_policy")}
        close={closeAbortModal}
      >
        <Box>
          <Typography>{t("abort_policy_text")}</Typography>
          <Box sx={{ mt: 2 }} display={"flex"} justifyContent={"space-between"}>
            <Button onClick={closeAbortModal}>{t("no")}</Button>
            <DeleteButton onClick={() => abortPolicy(id)} name={t("yes")} />
          </Box>
        </Box>
      </ModalDialog>
      <Card
        sx={{
          width: "100%",
          margin: "20px auto",
          padding: 2,
          boxShadow: 3,
          backgroundColor: "#ffffff",
          borderRadius: 2,
        }}
      >
        <CardContent>
          <Grid container spacing={4}>
            {/* Header */}
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      fontSize: {
                        xs: "20px",
                        sm: "24px",
                        md: "30px",
                        lg: "34px",
                      },
                      marginRight: 2, // Отступ между текстом и иконкой
                    }}
                  >
                    {data?.name}
                  </Typography>
                  <Chip
                    label={
                      isActive && !interruptedDate ? t("active") : t("inactive")
                    }
                    color={isActive && !interruptedDate ? "success" : "default"}
                    icon={<AccessTimeIcon sx={{ color: "grey" }} />}
                    sx={{
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                        lg: "20px",
                      },
                      padding: "6px 10px",
                    }}
                  />
                </Box>
                <Box display={"flex"}>
                  <Tooltip title={isActive && t("cant_update_active_policy")}>
                    <Box>
                      <Button
                        disabled={isActive || !policyShow || isPassed}
                        onClick={() => navigate("edit")}
                      >
                        {t("update_policy")}
                      </Button>
                    </Box>
                  </Tooltip>
                  {isActive && !interruptedDate && (
                    <DeleteButton
                      styles={{ ml: 2 }}
                      name={t("abort_policy")}
                      onClick={() => setAbortModal(true)}
                    />
                  )}
                </Box>
              </Box>
            </Grid>

            {/* Description */}
            {data?.description && (
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  sx={{
                    fontWeight: 500,
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "18px",
                      lg: "20px",
                    },
                  }}
                >
                  {t("description")}
                </Typography>
                <Box
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: 2,
                    padding: 2,
                    height: "150px",
                    overflow: "auto",
                    backgroundColor: "#f3f3f3",
                    marginBottom: 2,
                    mt: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                        lg: "20px",
                      },
                    }}
                  >
                    {data?.description}
                  </Typography>
                </Box>
              </Grid>
            )}

            {/* Columns with Details */}
            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                <CalendarTodayIcon sx={{ color: "grey", marginRight: 1 }} />
                <Typography variant="body1">
                  <strong>{t("start_date")}:</strong>{" "}
                  {t("dt_m", { val: new Date(startDate) })}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                <CalendarTodayIcon sx={{ color: "grey", marginRight: 1 }} />
                <Typography variant="body1">
                  <strong>{t("end_date")}:</strong>{" "}
                  {t("dt_m", { val: new Date(endDate) })}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                <SpeedIcon sx={{ color: "grey", marginRight: 1 }} />
                <Typography variant="body1">
                  <strong>{t("meter_refresh_rate")}:</strong>{" "}
                  {data?.meter_refresh_rate}
                </Typography>
              </Box>
              {interruptedDate && (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ marginBottom: 2 }}
                >
                  <AccessTimeIcon sx={{ color: "grey", marginRight: 1 }} />
                  <Typography variant="body1">
                    <strong>{t("interrupted")}:</strong>{" "}
                    {t("dt", { val: new Date(interruptedDate) })}
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                <LockIcon sx={{ color: "grey", marginRight: 1 }} />
                <Typography variant="body1">
                  <strong>{t("automatic_closing")}:</strong>{" "}
                  {data?.automatic_closing ? "Enabled" : "Disabled"}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                <DataThresholdingIcon sx={{ color: "grey", marginRight: 1 }} />
                <Typography variant="body1">
                  <strong>{t("consumption_threshold")}:</strong>{" "}
                  {data?.consumption_threshold} L
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                <SpeedIcon sx={{ color: "grey", marginRight: 1 }} />
                <Typography variant="body1">
                  <strong>{t("finally_meter_refresh_rate")}:</strong>{" "}
                  {data?.finally_meter_refresh_rate}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                <PercentIcon sx={{ color: "grey", marginRight: 1 }} />
                <Typography variant="body1">
                  <strong>{t("close_percentage")}:</strong>{" "}
                  {data?.close_percentage}%
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                <GroupIcon sx={{ color: "grey", marginRight: 1 }} />
                <Typography variant="body1">
                  <strong>{t("assigned_at_user")}:</strong>{" "}
                  <Link to={`/users/${data?.user_id}`}>{userShow && user}</Link>
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
                <SettingsIcon sx={{ color: "grey", marginRight: 1, mb: 4 }} />
                <Typography variant="body1">
                  <strong>{t("roles")}:</strong>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {data?.roles?.map((role, index) => (
                      <Chip
                        key={index}
                        label={role}
                        color="primary"
                        sx={{ fontSize: "14px", padding: "6px 10px" }}
                      />
                    ))}
                  </Box>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>

        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          sx={{
            marginTop: 2, // Добавить отступ сверху
            alignItems: "center", // Выравнивание по вертикали
            color: "text.secondary", // Тусклый цвет текста
            fontSize: "14px", // Меньший шрифт для текста
          }}
        >
          <Typography variant="body2">
            <strong>{t("last_update")}:</strong>{" "}
            {t("dt", {
              val: new Date(data?.updated_at),
            })}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default PolicyShowCard;
