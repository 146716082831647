import { store } from "../../../redux/store";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import debounce from "lodash.debounce";

const GroupsWidet = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);

  const [getGroups] = store.useLazyGetDevicegroupsIndexQuery();
  const groups = useSelector((state) => state?.deviceGroupsSlice?.deviceGroups);
  useEffect(() => {
    getGroups();
  }, []);

  const options = groups?.map((item) => ({
    label: item?.attributes?.name,
    value: item?.attributes?.id,
  }));

  useEffect(() => {
    console.log("props", props?.rawErrors);
  }, [props]);

  useEffect(() => {
    if (searchValue) {
      getGroups({ name: searchValue });
    }
  }, [searchValue]);

  useEffect(() => {
    if (value) {
      props?.onChange(value);
    }
  }, [value]);

  useEffect(() => {
    if (!value) {
      getGroups();
    }
  }, [value, searchValue]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {t("groups")}
      </AccordionSummary>
      <AccordionDetails>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={options}
          onChange={(event, newValue) => {
            setValue(newValue?.value);
          }}
          fullWidth
          renderInput={(params) => {
            return (
              <TextField
                onChange={debValue}
                {...params}
                value={value}
                label={t("import_configuration")}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            );
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default GroupsWidet;
