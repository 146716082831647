import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const GuideDigitalTwin = () => {

    const currentConfig = useSelector((state) => state?.importSlice?.selectedConfiguration);
    const { t } = useTranslation()
    return <div>
        <Typography variant="h5" gutterBottom> {t("digital_twin_available")}</Typography>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("digital_twin")}</TableCell>
                        <TableCell>{t("label")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentConfig?.attributes?.digital_twins?.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.mapping_label}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
}

export default GuideDigitalTwin