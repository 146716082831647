import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import React from "react";
import { transformObjectArray } from "../../utils/utilsFunctions";
import InputAdornment from "@mui/material/InputAdornment";
import { store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setTransofrmName } from "../../redux/slices/meterSlice";
import { nameTransform } from "../../utils/utilsFunctions";
import { useLazyGetDataByNameQuery } from "../../redux/slices/GetDataRtq";
import CircularProgress from "@mui/material/CircularProgress";
import debounce from "lodash.debounce";
import FormControlLabel from "@mui/material/FormControlLabel";
const WorkListClassSelector = (props) => {
  const [value, setValue] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [loading, setLoading] = useState("");
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [transformedOptions, setTransofrmedOptions] = useState([]);
  const dispatch = useDispatch();
  const defPropValue = Array.isArray(props?.schema?.default)
    ? props?.schema?.default
    : [];

  const savedValue =
    props?.schema?.multiple &&
    props?.formData?.map((item) => ({
      label: item,
      value: item,
    }));
  const [getByName, resp, lpi] = store.useLazyGetDataByNameQuery();
  let res;

  res = resp?.data?.data?.map((item) => ({
    label: `${item?.attributes?.name} (${item?.attributes?.version})`,
    value: item?.attributes?.id,
  }));

  const transformName = useSelector(
    (state) => state?.meterSlice?.transformName
  );
  const trnasormFn = (arr) => {
    return arr?.map((item) => ({
      key: item.attributes?.id,
      label: `${nameTransform(item.attributes?.name)} (${
        item.attributes?.version
      })`,
      value: item?.attributes?.id,
    }));
  };
  useEffect(() => {
    if (transformName) {
      setOptions(trnasormFn(resp?.data?.data));
    } else {
      setOptions(res);
    }
  }, [transformName, resp]);
  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);

  useEffect(() => {
    if (defPropValue && res && firstRender) {
      const twinsNames = resp?.data?.data
        ?.filter((twin) => defPropValue.includes(twin?.id))
        ?.map((item) => ({
          label: `${nameTransform(item?.attributes?.name)} (${
            item?.attributes?.version
          })`,
          value: item?.attributes?.id,
        }));

      setValue(twinsNames);
      setFirstRender(false);
    }
  }, [defPropValue, res]);

  useEffect(() => {
    if (value && !props?.schema?.multiple) {
      props.onChange(value.value.toString());
    }
    if (!value && !props?.schema?.multiple) {
      props.onChange(null);
    }
  }, [value]);
  //1
  useEffect(() => {
    if (value?.length >= 0) {
      props.onChange(value?.map((item) => item.value.toString()));
    }
  }, [value]);
  useEffect(() => {
    getByName([props.schema.url, {}]);
  }, []);

  const openFunc = () => {
    setOpen(true);
  };

  const valueIds = value?.map((item) => item?.value?.toString());
  const savedIds = Array.from(new Set([...(defPropValue || []), ...valueIds]));

  useEffect(() => {
    console.log("valentinka", savedIds);
  }, [savedIds]);

  useEffect(() => {
    if (!firstRender) {
      if (transformName) {
        const twinsNames = resp?.data?.data
          ?.filter((twin) => savedIds.includes(twin?.id))
          ?.map((item) => ({
            label: `${nameTransform(item?.attributes?.name)} (${
              item?.attributes?.version
            })`,
            value: item?.attributes?.id,
          }));
        setValue(twinsNames);
      } else {
        const twinsNames = resp?.data?.data
          ?.filter((twin) => savedIds.includes(twin?.id))
          ?.map((item) => ({
            label: `${item?.attributes?.name} (${item?.attributes?.version})`,
            value: item?.attributes?.id,
          }));
        setValue(twinsNames);
      }
    }
  }, [transformName]);

  return (
    <>
      <Autocomplete
        multiple={props?.schema?.multiple}
        fullWidth
        options={options ?? []}
        value={value}
        open={open}
        onOpen={openFunc}
        getOptionDisabled={(option) =>
          props?.schema?.filteredWidgets &&
          props?.schema?.filteredWidgets?.includes(option.value)
        }
        onClose={() => setOpen(false)}
        popupIcon={""}
        onChange={(e, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => {
          return (
            <Box display={"flex"} alignItems={"center"} width={450}>
              <TextField
                required={props.required}
                {...params}
                error={props?.rawErrors?.length > 0}
                label={props.schema.title}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {props?.schema?.checkbox && (
                <Tooltip title={"Hex to dec"}>
                  <FormControlLabel
                    required
                    labelPlacement="bottom"
                    control={
                      <Checkbox
                        defaultChecked
                        onClick={() => dispatch(setTransofrmName())}
                      />
                    }
                  />
                </Tooltip>
              )}
            </Box>
          );
        }}
      />
    </>
  );
};

export default React.memo(WorkListClassSelector);
