import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Handle, Position } from "@xyflow/react";
import { setNodes, onConnect } from "../../../redux/slices/nodeControllerSlice";
import { useDispatch } from "react-redux";
import { addCreatedId } from "../../../redux/slices/nodeControllerSlice";
import { v4 as uuidv4 } from "uuid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModalDialog from "../../meters/ModalDialog";
import theme from "../../../theme";
import { useTranslation } from "react-i18next";
import { store } from "../../../redux/store";
import { Link } from "react-router-dom";
import FormModal from "../FormModal";
import DinamicMeterCreate from "../DinamicMeterCreate";

export default function Node({ id, onAddNode, data }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(id == "parent" ? false : true);
  const [meterData, setMeterData] = useState(null);
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);
  const [addCompoundTwin, { isSuccess }] = store.useAddCompoundTwinMutation();
  useEffect(() => {
    console.log("valuepapa", data);
  }, [data]);

  const openModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  const handleClick = () => {
    const newNodeId = uuidv4();
    const newNode = {
      id: newNodeId,
      type: "node",
      data: { parentId: meterData?.id },
      position: {
        x: Math.random() * 400 + 100,
        y: Math.random() * 300 + 100,
      },
    };

    const newEdge = {
      id: uuidv4(),
      source: id,
      target: newNodeId,
      animated: true,
      style: { stroke: "#ff758c", strokeWidth: 2 },
    };

    dispatch(setNodes(newNode));
    dispatch(onConnect({ params: newEdge }));
  };

  useEffect(() => {
    console.log("meter data from node", meterData);
  }, [meterData]);

  useEffect(() => {
    if (id !== "parent" && meterData?.id) {
      dispatch(addCreatedId(id));
      const attributes = {
        device_id: +meterData?.id,
      };
      addCompoundTwin({
        attributes: attributes,
        parentId: data?.parentId,
        parentTwinId: meterData?.attributes?.digital_twin_id,
      });
    }
  }, [meterData?.id]);

  useEffect(() => {
    console.log("mtrdtfrmnd", meterData);
  }, [meterData]);

  return (
    <Box>
      <FormModal open={modal} close={closeModal} title={" "} cancell={false}>
        <DinamicMeterCreate
          setMeterData={setMeterData}
          closeModal={closeModal}
          meterData={meterData}
        />
      </FormModal>
      <Box
        sx={{
          width: 160,
          height: 160,
          borderRadius: "50%",
          background: meterData
            ? `linear-gradient(145deg, ${theme.palette.node.created},  ${theme.palette.node.created})`
            : `linear-gradient(145deg, ${theme.palette.node.creating}, ${theme.palette.node.creating}) `,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: hover
            ? "0px 10px 25px rgba(0, 0, 0, 0.3)"
            : "0px 5px 15px rgba(0, 0, 0, 0.15)",
          transition: "all 0.3s ease-in-out",
          position: "relative",
          cursor: "pointer",
          border: "2px solid rgba(255, 255, 255, 0.2)",
          textShadow: "0px 2px 4px rgba(0,0,0,0.2)",
          "&::after": {
            content: '""',
            position: "absolute",
            width: "90%",
            height: "90%",
            borderRadius: "50%",
            boxShadow: "inset 5px 5px 10px rgba(0,0,0,0.15)",
          },
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {id !== "parent" && (
          <Handle type="target" position={Position.Top} id="input" />
        )}

        <Handle type="source" position={Position.Bottom} id="output" />

        {meterData?.id && id == "parent" && (
          <IconButton
            sx={{
              position: "absolute",
              top: -20,
              right: -20,
              backgroundColor: "white",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
              zIndex: 1,
              transform: "scale(1)",
            }}
            onClick={handleClick}
          >
            <AddIcon sx={{ color: "#ff758c" }} />
          </IconButton>
        )}
        {meterData?.id && id !== "parent" && isSuccess && (
          <IconButton
            sx={{
              position: "absolute",
              top: -20,
              right: -20,
              backgroundColor: "white",
              boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
              zIndex: 1,
              transform: "scale(1)",
            }}
            onClick={handleClick}
          >
            <AddIcon sx={{ color: "#ff758c" }} />
          </IconButton>
        )}
        {meterData?.id && id == "parent" && (
          <Link to={`/meters/${meterData?.id}`} target="blank">
            <IconButton
              sx={{
                position: "absolute",
                top: -20,
                left: -20,
                backgroundColor: "white",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                zIndex: 1,
                transform: "scale(1)",
              }}
            >
              <VisibilityIcon sx={{ color: "#ff758c" }} />
            </IconButton>
          </Link>
        )}
        {meterData?.id && id !== "parent" && isSuccess && (
          <Link to={`/meters/${meterData?.id}`} target="blank">
            <IconButton
              sx={{
                position: "absolute",
                top: -20,
                left: -20,
                backgroundColor: "white",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
                zIndex: 1,
                transform: "scale(1)",
              }}
            >
              <VisibilityIcon sx={{ color: "#ff758c" }} />
            </IconButton>
          </Link>
        )}
        {hover && !meterData && (
          <IconButton onClick={openModal}>
            <AddIcon
              sx={{
                position: "absolute",

                fontSize: 40,
                zIndex: 2,
              }}
            />
          </IconButton>
        )}
        {meterData && (
          <Typography
            variant="subtitle2"
            color="white"
            onClick={openModal}
            sx={{
              textAlign: "center",
              wordBreak: "break-word",
              maxWidth: "80%",
              zIndex: 2,
            }}
          >
            {meterData?.attributes?.fields?.serial_number}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

// Добавляем стиль для пульсации
