import qs from "qs";
import { LatLng } from "leaflet";

/**
 * From bound return the distances (zoomLevel)
 * @param bounds Bounds
 *
 * @returns Integer the zoom level
 */
export const getDistance = ({
  _northEast: { lat: ne_lat, lng: ne_lng },
  _southWest: { lat: sw_lat, lng: sw_lng },
}) => {
  const ne = new LatLng(ne_lat, ne_lng);
  const sw = new LatLng(sw_lat, sw_lng);
  return ne?.distanceTo(sw) / 60;
};

export const createPostRequest = (attributes, type) => {
  return {
    data: {
      attributes: attributes,
      type: type,
    },
  };
};

export const prepareInAreaDevicesQueryString = (
  {
    _northEast: { lat: ne_lat, lng: ne_lng },
    _southWest: { lat: sw_lat, lng: sw_lng },
  },
  zoomLevel
) => {
  return {
    in_area: {
      ne_lat: ne_lat,
      ne_lng: ne_lng,
      sw_lng: sw_lng,
      sw_lat: sw_lat,
    },
    single_cluster: {
      zoom_level: zoomLevel,
    },
  };
};

export const prepareInAreaClustersQueryString = (
  {
    _northEast: { lat: ne_lat, lng: ne_lng },
    _southWest: { lat: sw_lat, lng: sw_lng },
  },
  zoomLevel
) => {
  return {
    in_area: {
      ne_lat: ne_lat,
      ne_lng: ne_lng,
      sw_lng: sw_lng,
      sw_lat: sw_lat,
    },
    zoom_level: zoomLevel,
  };
};
export const prepareInAreaQueryString = ({
  _northEast: { lat: ne_lat, lng: ne_lng },
  _southWest: { lat: sw_lat, lng: sw_lng },
}) => {
  return {
    in_area: {
      ne_lat: ne_lat,
      ne_lng: ne_lng,
      sw_lng: sw_lng,
      sw_lat: sw_lat,
    },
  };
};

export const convertParamsToString = (params) => {
  return qs.stringify(params);
};

/* export const prepareParams = (obj) => {
 
  let params = new URLSearchParams();
  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      params.set(`${key}`, obj[key]);
    } else {
      if (typeof obj[key] === "object") {
        const tmp = obj[key];
        for (const skey in tmp) {
          if (tmp[skey]) {
            params.set(`${skey}`, tmp[skey]);
          }
        }
      } else {
        if (obj[key]) {
          {
            params.set(`${key}`, obj[key]);
          }
        }
      }
    }
  }
  return params;
}; */

export const preparePolygonClasses = (arr) => {
  let params = new URLSearchParams();

  if (!Array.isArray(arr) || arr.length === 0) {
    console.error("Invalid input, expected an array of numbers");
    return params; // Возвращаем пустой URLSearchParams
  }

  // Для каждого id добавляем параметр digital_twin_ids[]
  arr.forEach((id) => {
    params.append("digital_twin_ids[]", id);
  });

  return params;
};

export const prepareParams = (obj) => {
  let params = new URLSearchParams();
  let valueExportAdded = false;

  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      if (key === "type") {
        params.set(`${key}`, JSON.stringify(obj[key]));
      } else if (key === "sorts") {
        obj[key].forEach(({ sort, field }) => {
          params.append("sorts[]", field);
          params.append("orders[]", sort);
        });
      } else if (key === "anomalies") {
        obj[key].forEach((item) => {
          params.append("anomaly_type_ids[]", item);
        });
      } else if (key === "export_consum") {
        obj[key].forEach(({ sort, field }) => {
          const fieldsToReplace = [
            "heat_value",
            "cold_value",
            "value_uncoverted",
            "value_converted",
            "value_converted",
            "value",
          ];
          if (fieldsToReplace.includes(field)) {
            if (!valueExportAdded) {
              field = "value_export";
              valueExportAdded = true;
            } else {
              return;
            }
          } else if (field === "date") {
            field = "date_export";
          }
          params.append("sorts[]", field);
          params.append("orders[]", sort);
        });
      } else if (key === "digital_twin_ids") {
        // Добавляем каждый элемент массива как отдельный параметр
        obj[key].forEach((id) => {
          params.append("digital_twin_ids[]", id);
        });
      } else {
        params.set(`${key}`, obj[key]);
      }
    } else {
      if (typeof obj[key] === "object") {
        const tmp = obj[key];
        for (const skey in tmp) {
          if (tmp[skey]) {
            params.set(`${skey}`, tmp[skey]);
          }
        }
      } else {
        if (obj[key]) {
          params.set(`${key}`, obj[key]);
        }
      }
    }
  }

  return params;
};

/* export const prepareParams = (obj) => {
  let params = new URLSearchParams();
  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      if (key == "type") {
        params.set(`${key}`, JSON.stringify(obj[key]));
      } else if (key == "sorts") {
        obj[key].forEach(({ sort, field }) => {
          params.append("sorts[]", field);
          params.append("orders[]", sort);
        });
      } else if (key == "anomalies") {
        obj[key].forEach((item) => {
          params.append("anomaly_type_ids[]", item);
        });
      } else if (key == "export_consum") {
        obj[key].forEach(({ sort, field }) => {
          const fieldsToReplace = [
            "heat_value",
            "cold_value",
            "value_uncoverted",
            "value_converted",
            "value_converted",
          ];
          if (fieldsToReplace.includes(field)) {
            field = "value_export";
          } else if (field === "date") {
            field = "date_export";
          }
          params.append("sorts[]", field);
          params.append("orders[]", sort);
        });
      } else {
        params.set(`${key}`, obj[key]);
      }
    } else {
      if (typeof obj[key] === "object") {
        const tmp = obj[key];
        for (const skey in tmp) {
          if (tmp[skey]) {
            params.set(`${skey}`, tmp[skey]);
          }
        }
      } else {
        if (obj[key]) {
          params.set(`${key}`, obj[key]);
        }
      }
    }
  }
  
  return params;
};
 */
/* export const prepareBodyParams = (obj) => {
  let params = {};
  let sortsIndex = 0;

  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      if (key === "type") {
        params[key] = JSON.stringify(obj[key]);
      } else if (key === "sorts") {
        obj[key].forEach(({ sort, field }) => {
          params[`sorts[]`] = field;
          params[`orders[]`] = sort;
        });
      }
    } else {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const tmp = obj[key];
        for (const skey in tmp) {
          if (tmp[skey]) {
            params[skey] = tmp[skey];
          }
        }
      } else {
        if (obj[key]) {
          params[key] = obj[key];
        }
      }
    }
  }

  return params;
}; */
export const prepareBodyParams = (obj) => {
  let params = {};

  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length > 0) {
      if (key === "type") {
        params[key] = JSON.stringify(obj[key]);
      } else if (key === "sorts") {
        let sortsArray = [];
        let ordersArray = [];
        obj[key].forEach(({ sort, field }) => {
          sortsArray.push(field);
          ordersArray.push(sort);
        });
        params["sorts"] = sortsArray;
        params["orders"] = ordersArray;
      }
    } else {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        const tmp = obj[key];
        for (const skey in tmp) {
          if (tmp[skey]) {
            params[skey] = tmp[skey];
          }
        }
      } else {
        if (obj[key]) {
          params[key] = obj[key];
        }
      }
    }
  }

  return params;
};

export const prepareBody = (attributes, id, type) => {
  const body = {
    data: {
      attributes: {
        ...attributes,
      },
      id: id,
      type: type,
    },
  };
  return body;
};

export const prepareBodyWithoutId = (attributes, type) => {
  const body = {
    data: {
      attributes: {
        ...attributes,
      },
      type: type,
    },
  };
  return body;
};
export const meterCreateBody = (attributes) => {
  const body = {
    data: {
      attributes: {
        ...attributes,
        meta_data: {},
      },
      type: "devices",
    },
  };
  return body;
};

export const prepareMeterAssocBody = (attributes, type) => {
  const body = {
    data: {
      attributes: {
        ...attributes,
      },
      type: type,
    },
  };
  return body;
};
export function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);
  //
  var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days ") : "";

  return dDisplay;
}
export const getDateDifferenceInDays = (date) => {
  const now = new Date();
  const currentDate = new Date(
    Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
  );
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  const readTimeDate = new Date(date);
  readTimeDate.setHours(0);
  readTimeDate.setMinutes(0);
  readTimeDate.setSeconds(0);
  readTimeDate.setMilliseconds(0);
  const diffInMs = Math.abs(currentDate - readTimeDate);
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays;
};
export const getDifferenceInDays = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Устанавливаем время на начало дня для обеих дат
  start.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);

  const diffInMs = Math.abs(end - start);
  const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

  return diffInDays;
};

export function timeAgo(seconds) {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = "";
  /* if (days > 0) { */
  days !== 1
    ? (formattedTime += days + " giorni, ")
    : (formattedTime += days + " giorno, ");
  /*  } */
  formattedTime +=
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    remainingSeconds.toString().padStart(2, "0");

  return formattedTime;
}
export function hasCommonValue(arr1, arr2) {
  return arr1?.some((value) => arr2?.includes(value));
}
export function removeDuplicateObjects(array, keyFn) {
  const seen = new Set();
  return array?.filter((item) => {
    const key = keyFn(item);
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
}

export const setErrorType = (str) => {
  if (str?.includes("Show")) {
    return "show";
  }
  return null;
};
