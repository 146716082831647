import { createSlice } from "@reduxjs/toolkit";
import { addEdge, applyNodeChanges, applyEdgeChanges } from "@xyflow/react";
const getTopCenterPosition = () => {
  const width = window.innerWidth * 0.8; // 80vw, как у ReactFlow
  return { x: width / 2 - 75, y: 50 }; // x - центр, y - верхняя часть
};

const initialState = {
  dataIsReady: false,
  nodeList: [
    {
      id: "parent",
      type: "node",
      data: {
        value: "test",
      },
      position: getTopCenterPosition(),
      isDeletable: false,
    },
  ],
  edgeList: [],
  createdIds: ["parent"],
  modal: false,
};

const nodeControllerSlice = createSlice({
  name: "nodeControllerSlice",
  initialState,
  reducers: {
    // Устанавливаем узлы
    setNodes(state, action) {
      const newNode = {
        id: action.payload?.id,
        type: action.payload?.type,
        data: action.payload?.data,
        position: action.payload.position ?? { x: 20, y: 20 },
      };

      // Если узел с таким id уже есть, заменяем его
      const index = state.nodeList.findIndex((node) => node.id === newNode.id);
      if (index !== -1) {
        state.nodeList[index] = newNode;
      } else {
        state.nodeList.push(newNode); // Добавляем новый узел
      }
    },

    updateNodes(state, action) {
      state.nodeList = applyNodeChanges(
        action.payload.changes.filter((change) => {
          if (
            change.type === "remove" &&
            state.createdIds.includes(change.id)
          ) {
            return false;
          }
          return true;
        }),
        state.nodeList
      );
    },

    // Обновляем рёбра
    updateEdges(state, action) {
      state.edgeList = applyEdgeChanges(
        action.payload.changes.filter((change) => {
          if (
            change.type === "remove" &&
            state.edgeList.some(
              (edge) =>
                edge.id === change.id &&
                (state.createdIds.includes(edge.source) ||
                  state.createdIds.includes(edge.target))
            )
          ) {
            return false;
          }
          return true;
        }),
        state.edgeList
      );
    },

    resetFlow(state) {
      state.edgeList = [];
      state.nodeList = [
        {
          id: "parent",
          type: "node",
          data: {
            value: "test",
          },
          position: getTopCenterPosition(),
          isDeletable: false,
        },
      ];
    },

    addCreatedId(state, action) {
      state.createdIds.push(action?.payload);
    },

    // Обработчик подключения рёбер
    onConnect(state, action) {
      state.edgeList = addEdge(action.payload.params, state.edgeList);
    },

    // Устанавливаем модальное окно
    setSwarmAppModal(state, action) {
      state.modal = action.payload;
    },

    // Устанавливаем переменные приложения
    setAppVariables(state, action) {
      state.appVariables = action.payload;
      localStorage.setItem("appVariables", JSON.stringify(state.appVariables));
    },

    // Устанавливаем свойства приложения
    setAppProperties(state, action) {
      state.properties = action.payload;
      localStorage.setItem("properties", JSON.stringify(state.properties));
    },

    // Добавляем новый раздел в записи
    addNewRecordSection(state, action) {
      const newProperty = action.payload;
      state.records[newProperty] = [];
    },

    // Удаляем раздел из записей
    removeRecordSection(state, action) {
      const propertyToRemove = action.payload;
      delete state.records[propertyToRemove];
    },

    // Устанавливаем выбранную запись
    setSelectedRecord(state, action) {
      if (action.payload) {
        state.selectedRecord = action.payload;
        localStorage.setItem("selectedRecord", state.selectedRecord);
      } else {
        state.selectedRecord = "";
        localStorage.removeItem("selectedRecord");
      }
    },

    // Добавляем переменные для выбранной записи
    addRecordsVariables(state, action) {
      state.records[state.selectedRecord] = action.payload;
      localStorage.setItem("records", JSON.stringify(state.records));
    },

    // Удаляем переменную из выбранной записи
    deleteRecordsVariable(state, action) {
      state.records[state.selectedRecord] = state.records[
        state.selectedRecord
      ].filter((item, i) => i !== action.payload);
    },

    // Получаем данные из локального хранилища
    getNodesFromLocalStorage(state) {
      state.nodeList = JSON.parse(localStorage.getItem("nodes")) ?? [];
      state.edgeList = JSON.parse(localStorage.getItem("edges")) ?? [];
    },
  },
});

export const {
  setNodes,
  updateNodes,
  updateEdges,
  onConnect,
  setSwarmAppModal,
  setAppVariables,
  addNewRecordSection,
  setAppProperties,
  removeRecordSection,
  setSelectedRecord,
  addRecordsVariables,
  deleteRecordsVariable,
  addCreatedId,
  getNodesFromLocalStorage,
  resetFlow,
} = nodeControllerSlice.actions;

export default nodeControllerSlice.reducer;
