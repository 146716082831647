import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IwdDataGrid from "../../pages/IwdDataGrid";
import { useSelector } from "react-redux";
import theme from "../../theme";

function DeviceGroupTable({ perPage, setPage, page }) {
  const { t } = useTranslation();
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const deviceGroups = useSelector(
    (state) => state?.deviceGroupsSlice?.deviceGroups
  );

  const meta = useSelector(
    (state) => state?.deviceGroupsSlice?.deviceGroupsMeta
  );
  const rows = deviceGroups?.map((item) => ({
    id: item?.attributes?.id ?? "--",
    name: item?.attributes?.name ?? "--",
    description: item?.attributes?.description ?? "--",
    creationData: t("dt", { val: new Date(item?.attributes?.inserted_at) }),
    actions: item?.attributes?.id,
  }));
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false, flex: 1 },
    {
      field: "name",
      headerName: t("name"),
      sortable: false,

      flex: 1,
    },
    {
      field: "description",
      headerName: t("description"),
      sortable: false,

      flex: 1,
    },

    {
      field: "creationData",
      headerName: t("inserted_at"),
      sortable: false,

      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,

      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            {/* <IconButton
              sx={{ float: "right" }}
            // onClick={() => onTrashClick(params.value)}
            >
              <DeleteIcon sx={{ ml: 1, fill: theme.palette.error.main }} />
            </IconButton> */}
            <Link to={`/device_groups/${params.value}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.navIcon }}
              />
            </Link>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ height: "57vh", mt: 1 }}>
      <IwdDataGrid
        rows={rows ?? []}
        columns={columns ?? []}
        perPage={perPage}
        setPage={setPage}
        page={page}
        count={meta?.count}
        sort={false}
        height={600}
        tableName={"districts"}
      />
    </Box>
  );
}

export default DeviceGroupTable;
