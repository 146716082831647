import { createSlice, current } from "@reduxjs/toolkit";
import { polygon } from "leaflet";

import { store } from "../store";
const colors = ["lime", "red", "purple", "yellow", "orange"];

const initialState = {
  polygonName: "",
  updating: false,
  district: null,
  polygons: [],
  districts: null,
  districtsMeta: null,
  devices: {},
  device: null,
  drawPolygon: null,
  currentPolygon: null,
  polygonsToRemove: [],
  newPolygonsInUpdate: [],
  deleteDistrictId: null,
  polygonCoordinates: null,
  freeColor: "",
  devicesToDownload: null,
  zoomPoligonInfo: null,
};
const deviceDisctrictSlice = createSlice({
  name: "deviceDistrictSlice",
  initialState,
  reducers: {
    setDistrict(state, action) {
      state.district = action.payload;
    },
    setDistricts(state, action) {
      state.districts = action.payload;
    },
    setDevice(state, action) {
      state.device = action.payload;
    },
    setDevices(state, action) {
      state.devices = action.payload;
    },
    setDrawPolygon(state, action) {
      state.drawPolygon = action.payload;
    },
    setCurrentPolygon(state, action) {
      state.currentPolygon = action.payload;
    },
    setPolygonsToremove(state, action) {
      state.polygonsToRemove = action.payload;
    },
    setPolygons(state, action) {
      state.polygons = action.payload;
    },
    clearDistrictAndPolygon(state, action) {
      state.district(null);
      state.polygons([]);
    },
    addPolygon(state, action) {
      const coords = action.payload;
      state.polygons = [
        ...state.polygons,
        {
          pathOptions: { color: state.freeColor },
          positions: coords,
          /*      polygon: action.payload, */
        },
      ];
    },
    addNewPolygonInUpdate(state, action) {
      const coords = action.payload;
      state.newPolygonsInUpdate = [
        ...state.newPolygonsInUpdate,
        {
          pathOptions: { color: state.freeColor },
          positions: coords,
          /*    polygon: action.payload, */
        },
      ];
    },
    cleanPolygonsInUpdate(state, action) {
      state.newPolygonsInUpdate = [];
    },
    removePolygon(state, action) {
      if (!action.payload?.item?.polygon?.geom) {
        state.polygons = current(state.polygons)?.filter(
          (item, i) => item !== action.payload.item
        );
        state.newPolygonsInUpdate = current(state.newPolygonsInUpdate)?.filter(
          (item, i) => item !== action.payload.item
        );
      }
      if (action?.payload?.item?.polygon?.geom) {
        state.polygonsToRemove = [
          ...state.polygonsToRemove,
          action.payload.item,
        ];
      }
    },
    discardRemoving(state, action) {
      state.polygonsToRemove = current(state.polygonsToRemove)?.filter(
        (item) => item !== action.payload
      );
    },
    setHigLight(state, action) {
      state.polygons = action.payload;
    },
    updatingOn(state) {
      state.updating = true;
    },
    updatingOf(state) {
      state.updating = false;
    },
    setDeleteDistrictId(state, action) {
      state.deleteDistrictId = action.payload;
    },
    setPolygonName(state, action) {
      state.polygonName = action.payload;
    },
    setPoligonCoordinates(state, action) {
      if (action.payload !== null) {
        const lat = action.payload[0]?.map((item) => item[0]);
        const lng = action.payload[0]?.map((item) => item[1]);
        const latMax = Math.max(...lat);
        const latMin = Math.min(...lat);
        const lngMax = Math.max(...lng);
        const lngMin = Math.min(...lng);
        const latCenter = (latMax - latMin) / 2 + latMin;
        const lngCenter = (lngMax - lngMin) / 2 + lngMin;

        state.polygonCoordinates = [latCenter, lngCenter];
      } else {
        state.polygonCoordinates = null;
      }
    },
    setFreeColor(state, action) {
      state.freeColor = action.payload;
    },
    setDevicesToDownload(state, action) {
      state.devicesToDownload = action.payload;
    },
    setZoomPoligonInfo(state, action) {
      state.zoomPoligonInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.deviceDistrictEndpoints.getPolygonDevices.matchFulfilled,
      (state, action) => {
        state.devices[state.polygonName] = action.payload.data;
        state.polygonName = "";
      }
    );
    builder.addMatcher(
      store.deviceDistrictEndpoints.getDistrictShow.matchFulfilled,
      (state, action) => {
        state.district = action.payload.data.attributes;
      }
    );
    builder.addMatcher(
      store.deviceDistrictEndpoints.getPolygons.matchFulfilled,
      (state, action) => {
        const downloadedPolygons = action.payload.data
          .sort((a, b) => a.id - b.id)
          ?.map(({ attributes }, i) => {
            const coords = attributes.geom.coordinates?.map((c) => c);
            return {
              pathOptions: {
                color: colors[i],
              },
              positions: coords,
              polygon: attributes,
            };
          });

        state.polygons = downloadedPolygons;
      }
    );
    builder.addMatcher(
      store.deviceDistrictEndpoints.getDistricts.matchFulfilled,
      (state, action) => {
        state.districts = action.payload.data;
        state.districtsMeta = action.payload.meta;
      }
    );
  },
});
export const actions = deviceDisctrictSlice.actions;

export default deviceDisctrictSlice.reducer;
