import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FormModal = ({ open, close, children, title, cancell = true }) => {
  const headerAppBarOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const { t } = useTranslation();

  return (
    <Box sx={{ mb: 2 }}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        fullWidth
        maxWidth="xl"
        sx={{
          ml: !headerAppBarOpen ? 6 : 32,
          mt: 3,
          "& .MuiDialog-paper": {
            minWidth: "80vw",
            maxWidth: "none",
            minHeight: "90vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle
          align="center"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {title || t("help")}
          {cancell && (
            <IconButton onClick={close}>
              <CancelIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ flexGrow: 1, overflow: "auto" }}>{children}</Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default FormModal;
