import { store } from "../../../redux/store";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SelectWidget = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);

  const options = props?.schema?.selectValues?.map((item) => ({
    label: item?.label,
    value: item?.value,
  }));

  useEffect(() => {
    if (value) {
      props.onChange(value);
    }
  }, [value]);

  useEffect(() => {
    console.log("propsselic", props);
  }, [props]);
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      onChange={(event, newValue) => {
        setValue(newValue?.value);
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          error={props?.rawErrors?.length > 0}
          {...params}
          label={`${t(`${props?.name}`)} ${props?.required && "*"}`}
        />
      )}
    />
  );
};

export default SelectWidget;
