import DropzoneWidget from "../worklist/worklistAqp/DropzoneWidget";
import ImportUpload from "./ImportUpload";
import { useState, useEffect } from "react";
import { store } from "../../redux/store";
import ConfigMapping from "./ConfigMapping";
import WsConnector from "../../utils/WsConnector";
import { setImportInfo, setImportUiid } from "../../redux/slices/importSlice";
import { loadingOff, loadingOn } from "../../redux/slices/loadingSlice";
import { setMessage } from "../../redux/slices/snackbarSlice";
import { setSeverity } from "../../redux/slices/snackbarSlice";
import { setConnect } from "../../redux/slices/importSlice";
import { useTranslation } from "react-i18next";
import { actions } from "../../redux/slices/iwdWebSocketSlice";
import { useDispatch, useSelector } from "react-redux";
import ConfigurationAutocomlete from "./ConfigurationAutocomlete";
import DoneIcon from "@mui/icons-material/Done";
import { v4 as uuidv4 } from "uuid";

import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  TextField,
  Divider,
  Button,
} from "@mui/material";
import { t } from "i18next";
import Guide from "./Guide";
import ModalDialog from "../meters/ModalDialog";
import { useNavigate } from "react-router-dom";

const Import = () => {
  const dispatch = useDispatch();
  const { data: socketData } = useSelector((state) => state.iwdWebSocketSlice);
  const [getConfig] = store.useLazyGetImportConfigurationsQuery();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [file, setFile] = useState(null);
  const [name, setName] = useState("");
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();
  const importUuid = useSelector((state) => state?.importSlice?.importUuid);
  const fileData = new FormData();
  const id = useSelector(
    (state) => state?.importSlice?.selectedConfiguration?.id
  );

  const closeModal = () => {
    setModal(false);
    navigate("/jobs", { replace: true });
  };
  const onSubmitClick = () => {
    const bearer = localStorage.getItem("user_token");
    const fileData = new FormData();
    fileData.append("file", file);
    fileData.append("name", name);
    fileData.append("uuid", importUuid);
    fileData.append("description", "some desk");

    dispatch(loadingOn());
    dispatch(setConnect(true));
    dispatch(actions.connect());
    fetch(`${window.BASE_URL}/api/v1/import_configuration/${id}/import`, {
      method: "POST",
      body: fileData,
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    })
      .then(async (res) => {
        if (!res.ok) {
          debugger;
          const errorText = await res.text();
          try {
            const errorJson = JSON.parse(errorText);
            const errorDetail = errorJson?.errors?.import_configuration_id?.[0];
            throw new Error(
              `HTTP error! Status: ${res.status}, Detail: ${errorDetail}`
            );
          } catch (errorJson) {
            const detail = JSON.parse(errorText);
            throw new Error(
              `HTTP error! Status: ${res.status}, ${detail.errors?.detail}`
            );
          }
        }

        if (res.status === 204) {
          setModal(true);
          return;
        }

        return res.json();
      })
      .then((data) => {
        dispatch(loadingOff());
      })
      .catch((err) => {
        console.error("Request error...", err);
        dispatch(loadingOff());
        dispatch(setSeverity("error"));
        dispatch(setMessage(t("name_already_taken")));
      });
  };

  useEffect(() => {
    console.log("soketdata", socketData);
  }, [socketData]);

  return (
    <Box display="flex" alignItems="flex-start" mt={5} gap={5}>
      <Card sx={{ width: "100%", p: 3, boxShadow: 3, borderRadius: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                {t("import")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label={t("name")}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <ConfigurationAutocomlete />
                </Grid>
                <Grid item xs={12}>
                  <ImportUpload file={file} setFile={setFile} />
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmitClick}
            disabled={!file || !name}
          >
            {t("import")}
          </Button>
        </Box>
      </Card>
      <Card sx={{ width: "100%", p: 3, boxShadow: 3, borderRadius: 3 }}>
        <Guide />
      </Card>
      <ModalDialog
        open={modal}
        title={t("operation_success")}
        close={closeModal}
        cancell={false}
      >
        <Box>
          <Typography>{t("import_insert_with_success")}</Typography>
          <Box
            sx={{ mt: 2 }}
            display={"flex"}
            justifyContent={"center"}
            alignContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={3}
          >
            <DoneIcon fontSize="large" sx={{ color: "green" }} />
            <Button color="success" onClick={closeModal}>
              {t("ok")}
            </Button>
          </Box>
        </Box>
      </ModalDialog>
    </Box>
  );
};

export default Import;
