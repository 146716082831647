import React, { useState, useRef, useEffect } from "react";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { hasCommonValue } from "../utils/api_params";
import theme from "../theme";

const MenuItemWithPopover = ({
  icon,
  title,
  children,
  link,
  section,
  popover = false,
  singleElement = false,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const permessions = window.MENU[section];
  const groups = useSelector((state) => state?.userPreferenceSlice.groups);
  const roles = useSelector((state) => state?.userPreferenceSlice.roles);
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const render = () => {
    if (permessions?.groups?.length > 0) {
      return hasCommonValue(groups, permessions?.groups);
    }
    if (permessions?.groups?.length === 0 || !permessions?.groups) {
      return hasCommonValue(roles, permessions?.roles);
    }
    return false;
  };

  useEffect(() => {
    console.log("link", link);
  }, [link]);

  return (
    <>
      {permessions?.visibility && render() && (
        <Box sx={{ position: "relative", cursor: "pointer" }}>
          <Tooltip title={popover && title}>
            <ListItem
              ref={anchorRef}
              button
              onClick={!singleElement ? handleToggle : () => navigate(link)}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          </Tooltip>

          {popover && (
            <Grow in={open} style={{ transformOrigin: "0 0 0" }}>
              <Paper
                sx={{
                  position: "fixed",
                  background: theme.palette.menu,
                  zIndex: 1300,
                  top: anchorRef.current
                    ? anchorRef.current.getBoundingClientRect().bottom
                    : 0,
                  left: anchorRef.current
                    ? anchorRef.current.getBoundingClientRect().left
                    : 0,
                  width: 200,
                  maxHeight: 700,
                  overflowY: "auto",
                  mt: -6,
                  ml: 7.5,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} sx={{ padding: 0 }}>
                    {React.Children.map(children, (child, index) => (
                      <MenuItem
                        onClick={() => {
                          console.log("child", child);
                          navigate(child.props.link);
                        }}
                        key={index}
                        sx={{
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        {child}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Box>
      )}
    </>
  );
};

export default MenuItemWithPopover;
