import * as React from "react";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/en";
import "dayjs/locale/it";

const MeterIndexDatePicker = (props) => {
  const defaultStartDate = props?.schema?.properties?.from?.default;
  const [startDate, setStartDate] = useState(
    defaultStartDate ? defaultStartDate : null
  );

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n?.language;

  const StartDateErrorMessage = props?.errorSchema?.from?.__errors[0];

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);

    if (newValue && newValue !== "Invalid Date") {
      const start = new Date(newValue);
      const from = new Date(
        Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0)
      );
      const to = new Date(
        Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), 23, 59)
      );

      props.onChange({
        from: from?.toISOString(),
        to: to?.toISOString(),
      });
    } else {
      props.onChange({ from: null, to: null });
    }
  };

  useEffect(() => {
    if (props?.schema?.refreshData) {
      setStartDate(null);
      props?.schema?.setRefreshData(false);
    }
  }, [props?.schema?.refreshData]);

  return (
    <Grid container>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={
          currentLanguage?.split("-")[0] !== "und"
            ? currentLanguage?.split("-")[0]
            : "en"
        }
      >
        <Grid item xs={12}>
          <DatePicker
            renderInput={(props) => (
              <TextField
                sx={{ width: "99%" }}
                {...props}
                error={!!StartDateErrorMessage}
                helperText={StartDateErrorMessage}
              />
            )}
            label={t("insertion_date")}
            value={startDate}
            onChange={handleStartDateChange}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default MeterIndexDatePicker;
