import { store } from "../../../redux/store";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";

const TagsWidget = (props) => {
  const [value, setValue] = useState(null);
  const [getByName, resp, lpi] = store.useLazyGetDataByNameQuery();
  useEffect(() => {
    getByName(["tag"]);
  }, []);

  const options = resp?.data?.data?.map((item) => ({
    label: item?.attributes?.name,
    value: item?.attributes?.name,
  }));

  useEffect(() => {
    if (value) {
      debugger;
      props?.onChange(value?.map((item) => item));
    }
  }, [value]);
  const pickedValues = value?.map((item) => item);
  useEffect(() => {
    console.log("tagval", value);
  }, [value]);
  return (
    <Autocomplete
      multiple
      disablePortal
      id="combo-box-demo"
      options={options?.filter((item) => !pickedValues?.includes(item?.value))}
      onChange={(event, newValue) => {
        debugger;
        setValue(newValue?.map((item) => item?.value));
      }}
      fullWidth
      renderInput={(params) => <TextField {...params} label="Tags" />}
    />
  );
};

export default TagsWidget;
