import { createSlice } from "@reduxjs/toolkit";
import { store } from "../store";

const initialState = {
  deviceGroups: null,
  deviceGroup: null,
  deviceGroupsMeta: null,
  devices: []
};

const deviceGroupsSlice = createSlice({
  name: "deviceGroupsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      store.deviceGroupsEndpoints.getDevicegroupsIndex.matchFulfilled,

      (state, action) => {
        state.deviceGroups = action.payload.data;
        state.deviceGroupsMeta = action.payload.meta;
      }
    );
    builder.addMatcher(
      store.deviceGroupsEndpoints.getDeviceGroupDevices.matchFulfilled,
      (state, action) => {
        state.devices = action.payload.data.map(({ attributes, id }) => { return { id: id, ...attributes } })
      }
    );
    builder.addMatcher(
      store.deviceGroupsEndpoints.getDeviceGroup.matchFulfilled,
      (state, action) => {
        state.deviceGroup = action.payload.data.attributes
      }
    )
  },
});
export const deviceGroupsActions = deviceGroupsSlice.actions;
export default deviceGroupsSlice.reducer;
