import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-chained-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { useDispatch, useSelector } from "react-redux";
const languageDetector = new LanguageDetector();

const transSelector = () => {
  switch (window.CLIENT) {
    case "mad":
      return "mad";
    default:
      return "translation";
  }
};

languageDetector.addDetector({
  name: "myDetectorsName",

  lookup(options) {
    // options -> are passed in options

    const user_info = localStorage.getItem("user_info");

    if (user_info) {
      return JSON.parse(user_info).preferred_language;
    }
    return null;
  },

  cacheUserLanguage(lng, options) {
    // options -> are passed in options
    // lng -> current language, will be called after init and on changeLanguage
    // store it
  },
});
i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      // order and from where user language should be detected
      order: [
        "myDetectorsName",
        "querystring",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      convertDetectedLanguage: (lng) => {
        console.log("Detected language before conversion:", lng);
        return lng.substring(0, lng.indexOf("-"));
      },
    },
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false,
      format: (value, format, lng, formatOptions) => {
        console.log("yazi4ara", lng);
        try {
          if (format === "datetime") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }).format(value);
          }
          if (format === "date") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "long",
              day: "numeric",
            }).format(value);
          }
          if (format === "datetime_n") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            }).format(value);
          }
          if (format === "datetime_nr") {
            const options = {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: false,
            };

            const formattedDate = new Intl.DateTimeFormat(lng, options).format(
              value
            );
            return formattedDate
              .replace(/-/, "/")
              .replace(/(\d+)\/(\d+)\/(\d+)/, "$2/$1/$3");
          }
          if (format === "date_n") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            }).format(value);
          }
          if (format === "date_n") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: undefined,
              minute: undefined,
              second: undefined,
            }).format(value);
          }
          if (format === "date_nosec") {
            return new Intl.DateTimeFormat(lng, {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: undefined,
            }).format(value);
          }
          if (format === "number") {
            if (formatOptions && formatOptions.maximumFractionDigits) {
              // Use the provided maximumFractionDigits option

              const formattedNumber = value.toLocaleString(lng, {
                maximumFractionDigits: formatOptions.maximumFractionDigits,
                minimumFractionDigits: formatOptions.minimumFractionDigits,
              });
              return formattedNumber;
            } else {
              // Fallback to the default maximumFractionDigits (e.g., 2)
              const formattedNumber = value.toLocaleString(lng, {
                maximumFractionDigits: 3,
                minimumFractionDigits: 3,
              });
              return formattedNumber;
            }
          }
          /*   if (format === "number") {
            const options = {
              maximumFractionDigits:
                formatOptions &&
                formatOptions.maximumFractionDigits !== undefined
                  ? formatOptions.maximumFractionDigits
                  : value.toString().split(".")[1]?.length || 0,
            };
            const formattedNumber = value.toLocaleString(lng, options);
            return formattedNumber;
          } */
        } catch (ex) {
          return "-";
        }
        return value;
      },
    },
    backend: {
      backends: [
        // LocalStorageBackend,  // primary backend
        HttpApi, // fallback backend
      ],
      backendOptions: [
        //   {

        // },
        {
          /* options for secondary backend */
          loadPath: `/locales/{{lng}}/${transSelector()}.json`, // http load path for my own fallback
        },
      ],
    },
  });

export default i18n;
