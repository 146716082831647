import { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { DropzoneDialog } from "material-ui-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";

import theme from "../../../theme";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { v4 as uuidv4 } from "uuid";
import { actions } from "../../../redux/slices/iwdWebSocketSlice";
import { setImportUiid } from "../../../redux/slices/importSlice";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

const bearer = localStorage.getItem("user_token");
const PolicyUpload = (props) => {
  const navigate = useNavigate();
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const {
    isWSConnected,
    data,
    error,
    channels,
    cashedData,

    loading: wsLoading,
  } = useSelector((state) => state.iwdWebSocketSlice);
  const { t } = useTranslation();
  const formData = new FormData();
  const [firstRender, setFirstRender] = useState(true);
  const importUuid = useSelector((state) => state?.importSlice?.importUuid);
  const [operationStatus, setOperationStatus] = useState(null);
  const dispatch = useDispatch();
  const [files, setFiles] = useState(null);
  const [uuid] = useState(uuidv4());
  const operationId = sessionStorage.getItem("uuid");

  const clearFile = () => {
    setFiles(null);
    props.setFile(null);
    setFirstRender(false);
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    /*   */

    setFiles(file);
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };
  useEffect(() => {
    if (files) {
      props.setFile(files);
    }
  }, [files]);
  useEffect(() => {
    if (files) {
      //   formData.append(files?.[0]?.name, files?.[0]);
      formData.append("file", files);
      formData.append("operation_id", uuid);
      dispatch(actions.connect());
      dispatch(setImportUiid(uuidv4()));
    }
  }, [files]);

  useEffect(() => {
    if (!files && isWSConnected && !firstRender) {
      if (Object.keys(channels).length > 0) {
        dispatch(actions.cleanData("update"));
        dispatch(actions.leaveChannel("general_info"));
      }

      dispatch(setImportUiid(null));
    }
  }, [files]);

  useEffect(() => {
    if (!importUuid) {
      setFiles(null);
    }
  }, [importUuid]);

  return (
    <Card sx={{ width: "100%", p: 3, boxShadow: 3, borderRadius: 3 }}>
      <CardContent>
        <Box sx={{ height: 365 }}>
          <Box
            sx={{
              class: "penis",
              width: "100%",
              height: 100,
              /*  "@media (width: 600px)": {
                width: appBarIsOpen ? 285 : 420,
              }, */
            }}
          >
            {files == null && (
              <Dropzone
                style={{ color: "red" }}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                maxFiles={1}
                styles={{
                  dropzone: { overflow: "hidden", height: 375 },
                }}
                inputContent={t("import_file")}
              />
            )}
            {files !== null && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start !important", // Используем !important, чтобы переопределить
                  alignItems: "center",
                  width: "100%",
                  padding: 0,
                  margin: 0,
                }}
              >
                <Typography variant="subtitle2" sx={{ mt: 0, mb: 0 }}>
                  {`File to upload : ${files?.name}`}
                </Typography>
                <Tooltip title="Delete file">
                  <IconButton onClick={clearFile} sx={{ padding: 0 }}>
                    <CloseIcon sx={{ fill: theme.palette.error.main }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          {/* 
          <Button

            variant={window.BUTTON_TYPE}
            onClick={uploadFile}
            disabled={!files}
          >
            {t("upload")}
          </Button> */}
        </Box>
      </CardContent>
    </Card>
  );
};
export default PolicyUpload;
