import { prepareParams } from "../../utils";
export const deviceGroupsApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getDevicegroupsIndex: build.query({
        query: (params) => {
          return `v1/device_group?${prepareParams(params)}`;
        },
      }),
      getDeviceGroup: build.query({
        query: (id) => {
          return `v1/device_group/${id}`
        }
      }),
      getDeviceGroupDevices: build.query({
        query: (id) => {
          return `v1/device_group/${id}/device`
        }
      })
    }),
  });
