import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { set } from "lodash";

const MeterTypeWidget = (props) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const type = props?.uiSchema?.["ui:options"]?.meterType;

  useEffect(() => {
    if (type) {
      debugger;
      switch (type) {
        case "08":
        case "8":
          setOptions([{ label: t("distributor"), value: "RIPARTITORE" }]);
          break;
        case "6":
        case "06":
        case "7":
        case "07":
        case "21":
        case "15":
        case "22":
        case "16":
          setOptions([
            { label: t("AFS"), value: "AFS" },
            { label: t("ACS"), value: "ACS" },
          ]);

          break;
        case "10":
        case "0A":
        case "11":
        case "0B":
        case "12":
        case "0C":
        case "13":
        case "0D":
          setOptions([
            { label: t("cal_fri"), value: "CALORIE/FRIGORIE" },
            { label: t("calories"), value: "Calorie" },
            { label: t("frigories"), value: "Frigorie" },
          ]);

          break;
        default:
          setOptions([
            { label: t("distributor"), value: "RIPARTITORE" },
            { label: t("AFS"), value: "AFS" },
            { label: t("ACS"), value: "ACS" },
            { label: t("cal_fri"), value: "CALORIE/FRIGORIE" },
            { label: t("calories"), value: "Calorie" },
            { label: t("frigories"), value: "Frigorie" },
          ]);

          break;
      }
    }
  }, [type]);
  const hadnleValue = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    if (value) {
      props?.onChange(value);
    }
  }, [value]);
  useEffect(() => {
    if (options?.length > 0) {
      setValue(options?.[0]?.value);
    }
  }, [options]);
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{t("meter_type")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={t("meter_type")}
          onChange={hadnleValue}
        >
          {options?.map((item) => {
            return (
              <MenuItem key={item?.value} value={item?.value}>
                {item?.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
export default MeterTypeWidget;
