import React, { useCallback, useEffect, useState } from "react";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  Controls,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { useDispatch, useSelector } from "react-redux";
import Node from "./nodes/Node";
import { Box, Typography, Switch } from "@mui/material";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { resetFlow } from "../../redux/slices/nodeControllerSlice";
import {
  updateNodes,
  updateEdges,
  onConnect,
} from "../../redux/slices/nodeControllerSlice";
import DinamicMeterCreate from "./DinamicMeterCreate";
import { useTranslation } from "react-i18next";

const nodeTypes = {
  node: Node,
};

export default function Graph() {
  const [withCompounds, setCompounds] = useState(false);
  const nodes = useSelector((state) => state?.nodeControllerSlice.nodeList);
  const edges = useSelector((state) => state?.nodeControllerSlice?.edgeList);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onNodesChange = useCallback((changes) =>
    dispatch(updateNodes({ changes: changes }))
  );

  const onEdgesChange = useCallback((changes) =>
    dispatch(updateEdges({ changes: changes }))
  );
  const onEdgeConnect = useCallback((params) =>
    dispatch(onConnect({ params: params }))
  );
  const proOptions = { hideAttribution: true };

  const handleChange = (event) => {
    setCompounds(event.target.checked);
    dispatch(resetFlow());
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("Meters/create"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);

  return (
    <>
      <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
        <Typography variant="h6">{t("compound_twins")}</Typography>
        <Switch
          checked={withCompounds}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>

      {!withCompounds ? (
        <DinamicMeterCreate singleMode />
      ) : (
        <div style={{ width: "80vw", height: "90vh" }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            connectable={false}
            proOptions={proOptions}
          />
        </div>
      )}
    </>
  );
}
