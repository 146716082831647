import validator from "@rjsf/validator-ajv6";

import { Form } from "@rjsf/mui";
import { useDispatch, useSelector } from "react-redux";
import * as React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { store } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import { setMessage } from "../../../redux/slices/snackbarSlice";
import {
  mappingCalculating,
  mappingCalculating10200,
} from "../../../utils/MappingCalculation";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import FormAccordion from "./Accordion";
import TextFieldNumber from "../../../utils/TextFieldNumber";
import Switch from "@mui/material/Switch";
import DoneIcon from "@mui/icons-material/Done";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import theme from "../../../theme";
import ModalDialog from "../../meters/ModalDialog";
import { StyledDataGrid } from "../../../pages/StyledDataGrid";

import HeatMeterSelect from "./HeatMeterSelect";
import { actions } from "../../../redux/slices/authSlice";
const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        return (
          <Grid key={element.id} alignItems="center" item xs={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const parseDecimalNumber = (stringValue) => {
  const numberValue = parseFloat(stringValue.replace(",", "."));
  return numberValue;
};

const HeatMeterForm = ({
  formData,
  setFormData,
  heatValidation,
  setHeatValidation,
  update = false,
  instanceId,
  twinId,
}) => {
  const selectedMeterClass = useSelector(
    (state) => state?.meterSlice?.selectedMeter
  )?.attributes?.digital_twin_fields?.class?.split(":")?.[0];
  const [updateMeter, { isSuccess: updateSuccess }] =
    store.useUpdateMeterMutation();
  const meterShow = useSelector((state) => state?.meterSlice?.meterShow); /* ??
    JSON.parse(localStorage.getItem("meterShow")); */
  const meterData = meterShow?.attributes;
  const [mode, setMode] = useState("Normale");
  const [project, setProject] = useState(
    update ? formData.project10200 : false
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [updateFc, setUpdateFc] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [kq, setKq] = useState("");
  const [fc, setFc] = useState("");
  const [defaultFc, setDefaultFc] = useState(false);
  const [k, setK] = useState("");
  const [height, setHeight] = useState("");
  const [depth, setDepth] = useState("");
  const [width, setwidth] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [c, setC] = useState(null);
  const [kc, setKc] = useState("");
  const [tableModal, setTableModal] = useState(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLocale = i18n.languages[0];
  const [selectedMeter, setSelectedmeter] = useState([]);
  const [meterName, setMeterName] = useState("");
  const meterClass = useSelector((state) => state?.meterSlice?.selectedMeter)
    ?.attributes?.digital_twin_fields?.manufacturer;

  const heatMeters10200 = [
    {
      name: "GHISA colonne piccole Sezione <= 30x3 mm Mozzo 50 mm",
      kcRBM: 1.53,
      kcEFE: 2.3,
      kcEFEse: 1.23, //KC EFE senso esterno
      kcSONtexN: 1.02, // normale
      kcSONtexSE: 1.21,
      c: 18000,
      immage: "/ghisa.png",
    },
    {
      name: "GHISA colonne piccole Sezione <= 30x30 mm Mozzo 55 mm",
      kcRBM: 1.7,
      kcEFE: 2.3,
      kcEFEse: 1.23,
      kcSONtexN: 1.02,
      kcSONtexSE: 1.21,
      c: 16900,
      immage: "/ghisa.png",
    },
    {
      name: "GHISA colonne grandi Sezione <= 30x30 mm Mozzo 55 mm",
      kcRBM: 1.72,
      kcEFE: 2.3,
      kcEFEse: 1.23,
      kcSONtexN: 1.02,
      kcSONtexSE: 1.21,
      c: 18000,
      immage: "/ghisa.png",
    },
    {
      name: "GHISA colonne grandi Sezione <= 30x30 mm Mozzo 60 mm",
      kcRBM: 1.63,
      kcEFE: 2.3,
      kcEFEse: 1.23,
      kcSONtexN: 1.02,
      kcSONtexSE: 1.21,
      c: 17000,
      immage: "/ghisa.png",
    },
    {
      name: "GHISA/ACCIAIO colonne unite da diaframma",
      kcRBM: 1.56,
      kcEFE: 2.27,
      kcEFEse: 1.08,
      kcSONtexN: 0.93,
      kcSONtexSE: 1.14,
      c: 16900,
      immage: "/ghisaAciaio.png",
    },
    {
      name: "GHISA PIASTRE colonne lisce",
      kcRBM: 1.72,
      kcEFE: 2.49,
      kcEFEse: null,
      kcSONtexN: 1.02,
      kcSONtexSE: 1.21,
      c: 20300,
      immage: "/ghisaPiastre.png",
    },
    {
      name: "GHISA PIASTRE colonne alettate",
      kcRBM: 1.72,
      kcEFE: 2.49,
      kcEFEse: null,
      kcSONtexN: 1,
      kcSONtexSE: 1.16,
      c: 21400,
      immage: "/ghisaPiastre.png",
    },
    {
      name: "ALLUMINIO molto alettato",
      kcRBM: 1.8,
      kcEFE: 2.35,
      kcEFEse: null,
      kcSONtexN: 1.19,
      kcSONtexSE: 1.35,
      c: 28100,
      immage: "/alluminio.png",
    },
    {
      name: "ALLUMINIO mediamente alettato",
      kcRBM: 1.8,
      kcEFE: 2.35,
      kcEFEse: null,
      kcSONtexN: 1.27,
      kcSONtexSE: 1.35,
      c: 24800,
      immage: "/alluminio.png",
    },
    {
      name: "ALLUMINIO poco alettato",
      kcRBM: 1.8,
      kcEFE: 2.35,
      kcEFEse: null,
      kcSONtexN: 1.27,
      kcSONtexSE: 1.35,
      c: 21400,
      immage: "/alluminio.png",
    },
    {
      name: "ACCIAIO piastra senza alettura",
      kcRBM: 1.66,
      kcEFE: 2.19,
      kcEFEse: 0.97,
      kcSONtexN: 0.95,
      kcSONtexSE: 0.98,
      c: 20300,
      immage: "/acciaio.png",
    },
    {
      name: "ACCIAIO alettura posteriore",
      kcRBM: 1.68,
      kcEFE: 2.19,
      kcEFEse: 0.97,
      kcSONtexN: 0.92,
      kcSONtexSE: 1.02,
      c: 23600,
      immage: "/acciaio.png",
    },
    {
      name: "ACCIAIO alettura tra I ranghi",
      kcRBM: 1.72,
      kcEFE: 2.19,
      kcEFEse: 0.97,
      kcSONtexN: 0.92,
      kcSONtexSE: 1.02,
      c: 22500,
      immage: "/acciaio.png",
    },
    {
      name: "ACCIAIO colonne piccole Sezione <= 30x30 mm Mozzo 50 mm",
      kcRBM: 1.53,
      kcEFE: 2.3,
      kcEFEse: 1.23,
      kcSONtexN: 1.02,
      kcSONtexSE: 1.21,
      c: null,
    },
    {
      name: "ACCIAIO colonne piccole Sezione <= 30x30 mm Mozzo 55 mm",
      kcRBM: 1.7,
      kcEFE: 2.3,
      kcEFEse: 1.23,
      kcSONtexN: 1.02,
      kcSONtexSE: 1.21,
      c: null,
    },
    {
      name: "ACCIAIO colonne grandi Sezione <= 30x30 mm Mozzo 55 mm",
      kcRBM: 1.72,
      kcEFE: 2.3,
      kcEFEse: 1.23,
      kcSONtexN: 1.02,
      kcSONtexSE: 1.21,
      c: null,
    },
    {
      name: "ACCIAIO colonne grandi Sezione <= 30x30 mm Mozzo 60 mm",
      kcRBM: 1.63,
      kcEFE: 2.3,
      kcEFEse: 1.23,
      kcSONtexN: 0.93,
      kcSONtexSE: 1.14,
      c: null,
    },

    {
      name: "TERMOARREDO",
      kcRBM: 2.1,
      kcEFE: 2.73,
      kcEFEse: null,
      kcSONtexN: 1.16,
      kcSONtexSE: 1.23,
      c: null,
    },
    {
      name: "TERMOCONVETTORE",
      kcRBM: null,
      kcEFE: null,
      kcEFEse: 1.23,
      kcSONtexN: null,
      kcSONtexSE: 1.2,
      c: null,
    },
  ];
  const heatMeters = [
    {
      name: "GHISA colonne piccole Sezione <= 30x3 mm Mozzo 50 mm",
      kcRBM: 1.53,
      kcEFE: 2.3,
      kcEFEse: 1.23, //KC EFE senso esterno
      kcSONtexN: 1.02, // normale
      kcSONtexSE: 1.21,
      c: 18000,
      immage: "/ghisa.png",
    },
    {
      name: "GHISA colonne piccole Sezione <= 30x30 mm Mozzo 55 mm",
      kcRBM: 1.7,
      kcEFE: 2.3,
      kcEFEse: 1.23,
      kcSONtexN: 1.02,
      kcSONtexSE: 1.21,
      c: 16900,
      immage: "/ghisa.png",
    },
    {
      name: "GHISA colonne grandi Sezione <= 30x30 mm Mozzo 55 mm",
      kcRBM: 1.72,
      kcEFE: 2.3,
      kcEFEse: 1.23,
      kcSONtexN: 1.02,
      kcSONtexSE: 1.21,
      c: 18000,
      immage: "/ghisa.png",
    },
    {
      name: "GHISA colonne grandi Sezione <= 30x30 mm Mozzo 60 mm",
      kcRBM: 1.63,
      kcEFE: 2.3,
      kcEFEse: 1.23,
      kcSONtexN: 1.02,
      kcSONtexSE: 1.21,
      c: 17000,
      immage: "/ghisa.png",
    },
    {
      name: "GHISA/ACCIAIO colonne unite da diaframma",
      kcRBM: 1.56,
      kcEFE: 2.27,
      kcEFEse: 1.08,
      kcSONtexN: 0.93,
      kcSONtexSE: 1.14,
      c: 16900,
      immage: "/ghisaAciaio.png",
    },
    {
      name: "GHISA PIASTRE colonne lisce",
      kcRBM: 1.72,
      kcEFE: 2.49,
      kcEFEse: null,
      kcSONtexN: 1.02,
      kcSONtexSE: 1.21,
      c: 20300,
      immage: "/ghisaPiastre.png",
    },
    {
      name: "GHISA PIASTRE colonne alettate",
      kcRBM: 1.72,
      kcEFE: 2.49,
      kcEFEse: null,
      kcSONtexN: 1,
      kcSONtexSE: 1.16,
      c: 21400,
      immage: "/ghisaPiastre.png",
    },
    {
      name: "ALLUMINIO molto alettato",
      kcRBM: 1.8,
      kcEFE: 2.35,
      kcEFEse: null,
      kcSONtexN: 1.19,
      kcSONtexSE: 1.35,
      c: 28100,
      immage: "/alluminio.png",
    },
    {
      name: "ALLUMINIO mediamente alettato",
      kcRBM: 1.8,
      kcEFE: 2.35,
      kcEFEse: null,
      kcSONtexN: 1.27,
      kcSONtexSE: 1.35,
      c: 24800,
      immage: "/alluminio.png",
    },
    {
      name: "ALLUMINIO poco alettato",
      kcRBM: 1.8,
      kcEFE: 2.35,
      kcEFEse: null,
      kcSONtexN: 1.27,
      kcSONtexSE: 1.35,
      c: 21400,
      immage: "/alluminio.png",
    },
    {
      name: "ACCIAIO piastra senza alettura",
      kcRBM: 1.66,
      kcEFE: 2.19,
      kcEFEse: 0.97,
      kcSONtexN: 0.95,
      kcSONtexSE: 0.98,
      c: 20300,
      immage: "/acciaio.png",
    },
    {
      name: "ACCIAIO alettura posteriore",
      kcRBM: 1.68,
      kcEFE: 2.19,
      kcEFEse: 0.97,
      kcSONtexN: 0.92,
      kcSONtexSE: 1.02,
      c: 23600,
      immage: "/acciaio.png",
    },
    {
      name: "ACCIAIO alettura tra I ranghi",
      kcRBM: 1.72,
      kcEFE: 2.19,
      kcEFEse: 0.97,
      kcSONtexN: 0.92,
      kcSONtexSE: 1.02,
      c: 22500,
      immage: "/acciaio.png",
    },
  ];
  const handleKeyPress = (event) => {
    if (event.key === "." || event.key === ",") {
      event.preventDefault();
    }
  };
  useEffect(() => {}, [k]);
  useEffect(() => {
    if (updateSuccess) {
      dispatch(setMessage("Meter aggiornato con successo"));
      navigate(`/meters/${instanceId}`);
    }
  }, [updateSuccess]);
  useEffect(() => {
    if (update && !formData?.project10200 && firstRender) {
      const selectedMeter = heatMeters10200?.filter(
        (item) => item.name === formData.radiator_type
      );
      setSelectedmeter(selectedMeter);
      setKq(formData.kq);
      setFc(formData.factory_converter);
      setK(formData.k);
      setHeight(formData.radiator_height);
      setDepth(formData.radiator_depth);
      setwidth(formData.radiator_width);
      setC(formData.radiator_c);
      setKc(formData.kc);
      setMode(formData.working_mode);
      setProject(formData.project10200);
      setMeterName(formData.radiator_type);
      setFirstRender(false);
    }
  }, [update, formData]);
  useEffect(() => {
    if (update && formData.kq && formData?.project10200 && firstRender) {
      const selectedMeter = heatMeters10200?.filter(
        (item) => item.name === formData.radiator_type
      );
      setSelectedmeter(selectedMeter);
      setKq(formData.kq);
      setFc(formData.factory_converter);
      setK(formData.k);

      setC(formData.radiator_c);
      setKc(formData.kc);
      setMode(formData.working_mode);
      setProject(formData.project10200);
      setMeterName(formData.radiator_type);
      setFirstRender(false);
    }
  }, [update, formData]);
  useEffect(() => {}, [heatValidation]);
  const validation = () => {
    if (heatValidation === "validation" && project) {
      if (
        kc &&
        kc !== "" &&
        kq &&
        kq !== "" &&
        fc &&
        fc !== "" &&
        k &&
        k !== ""
      ) {
        setHeatValidation("validated");
      } else {
        setHeatValidation("rejected");
      }
    }
    if (heatValidation === "validation" && !project) {
      if (
        kc &&
        kc !== "" &&
        kq &&
        kq !== "" &&
        fc &&
        fc !== "" &&
        k &&
        k !== "" &&
        height &&
        height !== "" &&
        width &&
        width !== "" &&
        depth &&
        depth !== ""
      ) {
        setHeatValidation("validated");
      } else {
        setHeatValidation("rejected");
      }
    }
  };
  useEffect(() => {}, [currentLocale]);
  useEffect(() => {
    validation();
  }, [heatValidation]);
  const cleanFields = () => {
    /*  setKc(""); */
    setHeight("");
    setwidth("");
    setDepth("");
    /*  setFc(""); */
    setK("");
    setKq("");
    /* setC(""); */
  };
  useEffect(() => {
    if (fc) {
      setFormData((prev) => ({
        ...prev,
        factory_converter: fc,
      }));
    }
    if (c) {
      setFormData((prev) => ({
        ...prev,
        radiator_c: c,
      }));
    }
    if (kc) {
      setFormData((prev) => ({
        ...prev,
        kc: kc,
      }));
    }
    if (height) {
      setFormData((prev) => ({
        ...prev,
        radiator_height: +height?.toString(),
      }));
    }
    if (depth) {
      setFormData((prev) => ({
        ...prev,
        radiator_depth: +depth?.toString(),
      }));
    }
    if (width) {
      setFormData((prev) => ({
        ...prev,
        radiator_width: +width?.toString(),
      }));
    }
    if (k) {
      setFormData((prev) => ({
        ...prev,
        k: k,
      }));
    }
    if (kq) {
      setFormData((prev) => ({
        ...prev,
        kq: +kq,
      }));
    }
    if (meterName) {
      setFormData((prev) => ({
        ...prev,
        radiator_type: meterName,
      }));
    }

    setFormData((prev) => ({
      ...prev,
      project10200: project,
    }));

    if (mode) {
      setFormData((prev) => ({
        ...prev,
        working_mode: mode,
      }));
    }
  }, [fc, kq, k, width, height, depth, kc, c, meterName, project, mode]);

  /*  useEffect(() => {
    if (!project) {
      setFormData((prev) => ({
        ...prev,
        factory_converter: null,
        kq: null,
        radiator_height: null,
        radiator_width: null,
        radiator_depth: null,
        k: null,
        radiator_c: null,
        kc: null,
      }));
    }
    if (project) {
      setFormData((prev) => ({
        ...prev,
        radiator_height: null,
        radiator_width: null,
        radiator_depth: null,
        radiator_c: null,
        k: null,
        kc: null,
        kq: null,
      }));
    }
  }, [project]); */

  const onDoneClick = (str) => {
    const selectedMeter = heatMeters10200?.filter((item) => item.name === str);
    setSelectedmeter(selectedMeter);
    setMeterName(selectedMeter[0].name);
    setTableModal(false);
    setC(selectedMeter[0].c);
    /*  cleanFields(); */
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "name",
      headerName: t("name"),
      type: "number",
      sortable: false,
      flex: 5,
      headerAlign: "left",
    },
    {
      field: "image",
      headerName: "Image",
      width: 150,

      renderCell: (params) =>
        params.value && <img src={window.IMAGE_URL + params.value} />, // renderCell will render the component
    },
    {
      field: "c",
      headerName: t("C"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 5,
      headerAlign: "left",
    },

    {
      field: "kcrbm",
      headerName: t("Kc RBM"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 5,
      headerAlign: "left",
    },
    {
      field: "kceffe",
      headerName: t("Kc EFE"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 3,
      headerAlign: "left",
    },
    {
      field: "kceffe_se",
      headerName: t("Kc EFE sensore esterno"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 5,
      headerAlign: "left",
    },

    {
      field: "kcine",
      headerName: t("Kc INE"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 3,
      headerAlign: "left",
    },
    {
      field: "kcine_se",
      headerName: t("Kc INE sensore esterno"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 5,
      headerAlign: "left",
    },

    {
      field: "kcsontes",
      headerName: t("Kc SONTEX normale"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 6,
      headerAlign: "left",
    },
    {
      field: "kcsontes_se",
      headerName: t("Kc SONTEX sonda esterna"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 6,
      headerAlign: "left",
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 4,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => onDoneClick(params.value)}
            >
              <DoneIcon sx={{ ml: 2, fill: theme.palette.success.main }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const rows = heatMeters?.map((item, i) => ({
    id: i,
    name: item.name,
    c: item.c,
    kcrbm: item.kcRBM,
    kceffe: item.kcEFE,
    kceffe_se: item.kcEFEse,
    kcine: item.kcEFE,
    kcine_se: item.kcEFEse,
    kcsontes: item.kcSONtexN,
    kcsontes_se: item.kcSONtexSE,
    actions: item.name,
    image: item.immage,
  }));
  const projetctRows = heatMeters10200?.map((item, i) => ({
    id: i,
    name: item.name,
    c: item.c,
    kcrbm: item.kcRBM,
    kceffe: item.kcEFE,
    kceffe_se: item.kcEFEse,
    kcine: item.kcEFE,
    kcine_se: item.kcEFEse,
    kcsontes: item.kcSONtexN,
    kcsontes_se: item.kcSONtexSE,
    actions: item.name,
    image: item.immage,
  }));
  const modeChange = (event) => {
    setMode(event.target.value);
    if (update) {
      setUpdateFc(true);
    }
  };
  const instalationType = selectedMeter?.[0];
  instalationType &&
    Object?.keys(instalationType)?.forEach((key) => {
      if (instalationType[key] == null) {
        delete instalationType[key];
      }
    });
  delete instalationType?.name;
  delete instalationType?.c;

  /*   useEffect(() => {
    if (!update || (update && updateFc)) {
      if (meterClass === "EFE" && mode === "2 Sensori") {
        setFc(2.228);
        setDefaultFc(true);
      }
      if (meterClass === "EFE" && mode === "Sensore esterno") {
        setFc(1.097);
        setDefaultFc(true);
      }
    }
  }, [meterClass, mode]); */
  const onHeightChange = (e) => {
    setHeight(e.target.value);
  };
  const onDepthChange = (e) => {
    setDepth(e.target.value);
  };
  const onwidthChange = (e) => {
    setwidth(e.target.value);
  };
  const onFcChange = (val) => {
    setFc(val);
  };
  const onKqChange = (val) => {
    setKq(val);
  };
  useEffect(() => {
    if (
      !project &&
      c !== "" &&
      height !== "" &&
      width !== "" &&
      depth !== "" &&
      kc !== "" &&
      fc !== ""
    ) {
      const res = mappingCalculating(height, width, depth, c, kc, fc);
      setK(res.k);
      setKq(res.kq);
    }
  }, [project, height, width, depth, c, kc, fc]);
  useEffect(() => {
    if (project && kq !== "" && fc !== "" && kc !== "") {
      const res = mappingCalculating10200(kq, kc, fc);
      setK(res);
    }
  }, [project, fc, kc, kq, selectedMeter]);
  const onProjectClick = () => {
    setProject(!project);
    setHeatValidation("waiting");
    cleanFields();
  };
  useEffect(() => {}, [height]);
  const onUpdateClick = () => {
    /*    delete formData.meter_type; */

    setHeatValidation("validation");
  };

  useEffect(() => {
    console.log("seledo4ka", selectedMeter);
  }, [selectedMeter]);

  useEffect(() => {
    if (heatValidation == "validated" && update) {
      const attributes = {
        fields: {
          coordinates: {
            coordinates: [
              formData?.coordinates?.lng,
              formData?.coordinates?.lat,
            ],
            type: "Point",
          },
          class: meterData?.class,
          user_id: meterData?.user_id,
          serial_number: meterData?.serial_number,
          digital_twin_id: meterData?.digital_twin_id,
          project10200: formData.project10200,
          radiator_c: formData?.radiator_c,
          radiator_depth: formData?.radiator_depth,
          radiator_height: formData?.radiator_height,
          radiator_type: formData?.radiator_type,
          radiator_width: formData?.radiator_width,
          factory_converter: formData?.factory_converter,
          k: formData?.k,
          kc: formData?.kc,
          kq: formData?.kq,
        },
      };
      const projectAttributes = {
        fields: {
          coordinates: {
            coordinates: [
              formData?.coordinates?.lng,
              formData?.coordinates?.lat,
            ],
            type: "Point",
          },
          class: meterData?.class,
          user_id: meterData?.user_id,
          serial_number: meterData?.serial_number,
          digital_twin_id: meterData?.digital_twin_id,
          project10200: formData.project10200,
          radiator_c: formData?.radiator_c,
          radiator_depth: null,
          radiator_height: null,
          radiator_type: formData?.radiator_type,
          radiator_width: null,
          factory_converter: formData?.factory_converter,
          k: formData?.k,
          kc: formData?.kc,
          kq: formData?.kq,
        },
      };
      updateMeter({
        attributes: !project ? attributes : projectAttributes,
        instanceId: instanceId,
        twinId: twinId,
      });
    }
  }, [heatValidation]);
  return (
    <>
      {!firstRender && update && (
        <>
          <ModalDialog
            title={"Heat Meters"}
            open={tableModal}
            close={() => setTableModal(false)}
          >
            <Box sx={{ height: "70vh", width: "80vw" }}>
              <StyledDataGrid
                disableColumnMenu
                rows={project ? projetctRows : rows}
                columns={columns}
                rowCount={project ? heatMeters10200.length : heatMeters.length}
                pageSize={perPage}
                rowsPerPageOptions={[perPage]}
                disableSelectionOnClick
                onPageChange={(pageNumber) => {
                  setPage(pageNumber + 1);
                }}
                page={page - 1}
                getRowHeight={() => "auto"}
              />
            </Box>
          </ModalDialog>
          <FormAccordion
            expanded={true}
            width={"100%"}
            title={"Ripartitore di calore"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Typography>Esiste un progetto 10200 ?</Typography>
                <Switch onClick={onProjectClick} defaultChecked={project} />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  color={!meterName && theme?.palette.error.main}
                  sx={{
                    mr: 3,
                  }}
                >
                  {meterName ??
                    "Mancano i campi. Per favore, seleziona il tipo di radiatore."}
                </Typography>

                <Button
                  onClick={() => setTableModal(true)}
                  variant={window.BUTTON_TYPE}
                  sx={{ mr: 2, mb: 2 }}
                >
                  Tipo radiatore
                </Button>
                {/*  <Box sx={{ width: 240, mr: 5 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Modalità Funzionamento
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      
                      id="demo-simple-select"
                      value={mode}
                      label="Modalità Funzionamento"
                      onChange={(e) => modeChange(e)}
                    >
                      <MenuItem value={"Normale"}>Normale</MenuItem>
                      <MenuItem value={"2 Sensori"}>2 Sensori</MenuItem>
                      <MenuItem value={"Sensore esterno"}>
                        Sensore esterno
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box> */}
              </Box>
            </Box>
            {project && (
              <Grid container sx={{ mt: 4 }} spacing={1}>
                <Grid item xs={12}>
                  <TextFieldNumber
                    value={kq}
                    onChange={onKqChange}
                    sx={{ width: "100%" }}
                    label={"Potenza termica (kW)"}
                    error={heatValidation === "rejected" && (!kq || kq === "")}
                    helperText={
                      heatValidation === "rejected" &&
                      (!kq || kq === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <HeatMeterSelect
                    selectedMeter={selectedMeter}
                    obj={instalationType ?? []}
                    setKc={setKc}
                    kc={kc}
                    meterName={meterName}
                    update={update}
                    setFc={setFc}
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextField
                    disabled
                    value={t("number", {
                      val: kc,
                      maximumFractionDigits: 2,
                    })}
                    sx={{ width: "95%" }}
                    label="Kc"
                    InputLabelProps={{ shrink: true }}
                    error={heatValidation === "rejected" && (!kc || kc === "")}
                    inputProps={{
                      step: "0.01",
                    }}
                    helperText={
                      heatValidation === "rejected" &&
                      (!kc || kc === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextFieldNumber
                    value={fc}
                    sx={{ width: "95%" }}
                    onChange={onFcChange}
                    label="Fattore di conversione"
                    error={heatValidation === "rejected" && (!fc || fc === "")}
                    /*   inputProps={{ step: "0.1", lang: "it-IT" }} */
                    helperText={
                      heatValidation === "rejected" &&
                      (!fc || fc === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextField
                    value={t("number", {
                      val: k,
                      maximumFractionDigits: 3,
                    })}
                    disabled
                    sx={{ width: "100%" }}
                    label="K"
                    inputProps={{
                      step: "0.01",
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {!project && (
              <Grid container sx={{ mt: 4 }} spacing={1}>
                <Grid item xs={2}>
                  <TextField
                    value={height}
                    type="number"
                    onKeyDown={handleKeyPress}
                    onChange={(e) => onHeightChange(e)}
                    sx={{ width: "95%" }}
                    label="Altezza (mm)"
                    error={
                      heatValidation === "rejected" &&
                      (!height || height === "")
                    }
                    inputProps={{
                      step: "0.01",
                    }}
                    helperText={
                      heatValidation === "rejected" &&
                      (!height || height === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={width}
                    type="number"
                    onKeyDown={handleKeyPress}
                    onChange={(e) => onwidthChange(e)}
                    sx={{ width: "95%" }}
                    label="Larghezza (mm)"
                    error={
                      heatValidation === "rejected" && (!width || width === "")
                    }
                    helperText={
                      heatValidation === "rejected" &&
                      (!width || width === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={depth}
                    onChange={(e) => onDepthChange(e)}
                    sx={{ width: "95%" }}
                    label="Profondita (mm)"
                    error={
                      heatValidation === "rejected" && (!depth || depth === "")
                    }
                    type="number"
                    onKeyDown={handleKeyPress}
                    helperText={
                      heatValidation === "rejected" &&
                      (!depth || depth === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    value={!meterName ? "Seleziona tipo radiatore" : c}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "95%" }}
                    label="C"
                    error={heatValidation === "rejected" && (!c || c === "")}
                    inputProps={{
                      step: "0.01",
                    }}
                    helperText={
                      heatValidation === "rejected" &&
                      (!c || c === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    /*     value={t("number", {
                      val: kq,
                    })} */
                    value={kq}
                    sx={{ width: "100%" }}
                    label="Potenza termica (kW)"
                    error={heatValidation === "rejected" && (!kq || kq === "")}
                    inputProps={{
                      step: "0.01",
                    }}
                    helperText={
                      heatValidation === "rejected" &&
                      (!kq || kq === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <HeatMeterSelect
                    selectedMeter={selectedMeter}
                    obj={instalationType ?? []}
                    setKc={setKc}
                    kc={kc}
                    meterName={meterName}
                    update={update}
                    setFc={setFc}
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextField
                    disabled
                    value={t("number", {
                      val: kc,
                      maximumFractionDigits: 2,
                    })}
                    sx={{ width: "95%" }}
                    label="Kc"
                    InputLabelProps={{ shrink: true }}
                    error={heatValidation === "rejected" && (!kc || kc === "")}
                    inputProps={{
                      step: "0.01",
                    }}
                    helperText={
                      heatValidation === "rejected" &&
                      (!kc || kc === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextFieldNumber
                    value={fc}
                    sx={{ width: "95%" }}
                    onChange={onFcChange}
                    label="Fattore di conversione"
                    error={heatValidation === "rejected" && (!fc || fc === "")}
                    /*   inputProps={{ step: "0.1", lang: "it-IT" }} */
                    helperText={
                      heatValidation === "rejected" &&
                      (!fc || fc === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextField
                    disabled
                    value={t("number", {
                      val: k,
                      maximumFractionDigits: 3,
                    })}
                    sx={{ width: "100%" }}
                    error={heatValidation === "rejected" && !k}
                    helperText={
                      heatValidation === "rejected" && !k && t("incorect_data")
                    }
                    label="K"
                    inputProps={{
                      step: "0.01",
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </FormAccordion>
          <Box
            sx={{ mt: 2 }}
            display={"flex"}
            width={"100%"}
            justifyContent={"flex-end"}
          >
            <Button onClick={onUpdateClick} variant={window.BUTTON_TYPE}>
              {t("update")}
            </Button>
          </Box>
        </>
      )}
      {!update && (
        <>
          <ModalDialog
            title={"Heat Meters"}
            open={tableModal}
            close={() => setTableModal(false)}
          >
            <Box sx={{ height: "70vh", width: "80vw" }}>
              <StyledDataGrid
                disableColumnMenu
                rows={project ? projetctRows : rows}
                columns={columns}
                rowCount={project ? heatMeters10200.length : heatMeters.length}
                pageSize={perPage}
                rowsPerPageOptions={[perPage]}
                disableSelectionOnClick
                onPageChange={(pageNumber) => {
                  setPage(pageNumber + 1);
                }}
                page={page - 1}
                getRowHeight={() => "auto"}
              />
            </Box>
          </ModalDialog>
          <FormAccordion
            expanded={true}
            width={"100%"}
            title={"Ripartitore di calore"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Typography>Esiste un progetto 10200 ?</Typography>
                <Switch onClick={onProjectClick} defaultChecked={project} />
              </Box>
              <Box display={"flex"} alignItems={"center"}>
                <Typography sx={{ mr: 3 }}>{meterName}</Typography>
                <Button
                  onClick={() => setTableModal(true)}
                  variant={window.BUTTON_TYPE}
                  sx={{ mr: 2, mb: 2 }}
                >
                  Tipo radiatore
                </Button>
                {/* <Box sx={{ width: 240, mr: 5 }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Modalità Funzionamento
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={mode}
                      label="Modalità Funzionamento"
                      onChange={(e) => modeChange(e)}
                    >
                      <MenuItem value={"Normale"}>Normale</MenuItem>
                      <MenuItem value={"2 Sensori"}>2 Sensori</MenuItem>
                      <MenuItem value={"Sensore esterno"}>
                        Sensore esterno
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box> */}
              </Box>
            </Box>
            {project && (
              <Grid container sx={{ mt: 4 }} spacing={1}>
                <Grid item xs={12}>
                  <TextFieldNumber
                    value={kq}
                    onChange={onKqChange}
                    sx={{ width: "100%" }}
                    label={"Potenza termica (kW)"}
                    error={heatValidation === "rejected" && (!kq || kq === "")}
                    helperText={
                      heatValidation === "rejected" &&
                      (!kq || kq === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <HeatMeterSelect
                    selectedMeter={selectedMeter}
                    obj={instalationType ?? []}
                    setKc={setKc}
                    kc={kc}
                    meterName={meterName}
                    update={update}
                    setFc={setFc}
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextField
                    disabled
                    value={t("number", {
                      val: kc,
                      maximumFractionDigits: 2,
                    })}
                    sx={{ width: "95%" }}
                    label="Kc"
                    InputLabelProps={{ shrink: true }}
                    error={heatValidation === "rejected" && (!kc || kc === "")}
                    inputProps={{
                      step: "0.01",
                    }}
                    helperText={
                      heatValidation === "rejected" &&
                      (!kc || kc === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextFieldNumber
                    value={fc}
                    sx={{ width: "95%" }}
                    onChange={onFcChange}
                    label="Fattore di conversione"
                    error={heatValidation === "rejected" && (!fc || fc === "")}
                    /*   inputProps={{ step: "0.1", lang: "it-IT" }} */
                    helperText={
                      heatValidation === "rejected" &&
                      (!fc || fc === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextField
                    value={t("number", {
                      val: k,
                    })}
                    disabled
                    sx={{ width: "100%" }}
                    label="K"
                    inputProps={{
                      step: "0.01",
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {!project && (
              <Grid container sx={{ mt: 4 }} spacing={1}>
                <Grid item xs={2}>
                  <TextField
                    value={height}
                    type="number"
                    onKeyDown={handleKeyPress}
                    onChange={(e) => onHeightChange(e)}
                    sx={{ width: "95%" }}
                    label="Altezza (mm)"
                    error={
                      heatValidation === "rejected" &&
                      (!height || height === "")
                    }
                    helperText={
                      heatValidation === "rejected" &&
                      (!height || height === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={width}
                    type="number"
                    onKeyDown={handleKeyPress}
                    onChange={(e) => onwidthChange(e)}
                    sx={{ width: "95%" }}
                    label="Larghezza (mm)"
                    error={
                      heatValidation === "rejected" && (!width || width === "")
                    }
                    inputProps={{
                      step: "0.01",
                    }}
                    helperText={
                      heatValidation === "rejected" &&
                      (!width || width === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    value={depth}
                    type="number"
                    onKeyDown={handleKeyPress}
                    onChange={(e) => onDepthChange(e)}
                    sx={{ width: "95%" }}
                    label="Profondita (mm)"
                    error={
                      heatValidation === "rejected" && (!depth || depth === "")
                    }
                    inputProps={{
                      step: "0.01",
                    }}
                    helperText={
                      heatValidation === "rejected" &&
                      (!depth || depth === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    value={!meterName ? "Seleziona tipo radiatore" : c}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: "95%" }}
                    label="C"
                    error={heatValidation === "rejected" && (!c || c === "")}
                    inputProps={{
                      step: "0.01",
                    }}
                    helperText={
                      heatValidation === "rejected" &&
                      (!c || c === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    disabled
                    value={t("number", {
                      val: kq,
                      maximumFractionDigits: 2,
                    })}
                    /* value={kq} */
                    sx={{ width: "100%" }}
                    label="Potenza termica (kW)"
                    error={heatValidation === "rejected" && (!kq || kq === "")}
                    inputProps={{
                      step: "0.01",
                    }}
                    helperText={
                      heatValidation === "rejected" &&
                      (!kq || kq === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <HeatMeterSelect
                    obj={instalationType ?? []}
                    setKc={setKc}
                    selectedMeter={selectedMeter}
                    kc={kc}
                    meterName={meterName}
                    update={update}
                    setFc={setFc}
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextField
                    disabled
                    value={t("number", {
                      val: kc,
                      maximumFractionDigits: 2,
                    })}
                    sx={{ width: "95%" }}
                    label="Kc"
                    InputLabelProps={{ shrink: true }}
                    error={heatValidation === "rejected" && (!kc || kc === "")}
                    inputProps={{
                      step: "0.01",
                    }}
                    helperText={
                      heatValidation === "rejected" &&
                      (!kc || kc === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextFieldNumber
                    value={fc}
                    sx={{ width: "95%" }}
                    onChange={onFcChange}
                    label="Fattore di conversione"
                    error={heatValidation === "rejected" && (!fc || fc === "")}
                    /*   inputProps={{ step: "0.1", lang: "it-IT" }} */
                    helperText={
                      heatValidation === "rejected" &&
                      (!fc || fc === "") &&
                      "is a required property"
                    }
                  />
                </Grid>
                <Grid item xs={3} sx={{ mt: 3 }}>
                  <TextField
                    disabled
                    value={t("number", {
                      val: k,
                      maximumFractionDigits: 3,
                    })}
                    sx={{ width: "100%" }}
                    error={heatValidation === "rejected" && !k}
                    helperText={
                      heatValidation === "rejected" && !k && t("incorect_data")
                    }
                    label="K"
                    inputProps={{
                      step: "0.01",
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </FormAccordion>
        </>
      )}
    </>
  );
};
export default HeatMeterForm;
