import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "use-http";
import { useTranslation } from "react-i18next";
import {
  Stack,
  Box,
  Button,
  Typography,
  Tooltip,
  Divider,
} from "@mui/material";
import Loader from "../components/Loader";
import { convertParamsToString } from "../utils";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import IwdTable, { useIwdTable } from "../components/IwdTable";
import DeleteDialog from "../components/DeleteDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useIwdBreadCrumb } from "../components/IwdBreadcrumbProvider";
import theme from "../theme";
import DeviceGroupSearchProvider, {
  DeviceGroupSearchConsumer,
} from "../components/deviceGroup/DeviceGroupSearchProvider";
import { store } from "../redux/store";
import IwdDataGrid from "./IwdDataGrid";
import { DataGrid } from "@mui/x-data-grid";
import { setBreadCrumbs } from "../redux/slices/breadCrumbMeterSlice";

const DeviceGroup = () => {
  const [selectedId, setSelectedId] = useState({});
  const { setLast } = useIwdBreadCrumb();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getDeviceGroup] = store.useLazyGetDeviceGroupQuery();
  const [getDeviceGroupDevices] = store.useLazyGetDeviceGroupDevicesQuery();
  const { id } = useParams();
  const deviceGroup = useSelector((state) => state?.deviceGroupsSlice?.deviceGroup);
  const deviceGroupDevices = useSelector((state) => state?.deviceGroupsSlice?.devices);

  const { t } = useTranslation();
  useEffect(() => {
    if (deviceGroup == null || deviceGroup.id != id) {
      getDeviceGroup(id)
      getDeviceGroupDevices(id)
    }
    else {
      dispatch(setBreadCrumbs(deviceGroup.name));
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [deviceGroup])
  const columns = [
    { field: "id", headerName: "ID", width: 90, sortable: false, flex: 1 },
    {
      field: "serial_number",
      headerName: t("serial_number"),
      sortable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,

      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Link to={`/meters/${params.id}`}>
              <VisibilityIcon
                sx={{ float: "right", mt: 1, fill: theme.palette.navIcon }}
              />
            </Link>
          </Box>
        );
      },
    }
  ]
  const paginationModel = { page: 0, pageSize: 5 };

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Typography variant="h4" sx={{ display: "flex" }}>
        {t("group")}: {deviceGroup?.name}{" "}
      </Typography>
      <Typography sx={{ marginBottom: 2, marginTop: 2 }}>
        {deviceGroup?.description}
      </Typography>
      <Divider sx={{ marginBottom: 5 }} />
      <Typography variant="h5" sx={{ marginBottom: 3 }} >
        {t('associate_devices')}
      </Typography>
      <Box sx={{ marginBottom: 3, width: "100%", height: 600 }}>
        <DataGrid
          rows={deviceGroupDevices ?? []}
          columns={columns ?? []}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[20, 10]}
          hideFooter={true}
          disableColumnMenu
        />
      </Box>
    </Box >
  );
};

export default DeviceGroup;
