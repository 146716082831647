

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Paper, Typography } from "@mui/material";
import { Box, minWidth, width } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

const renderTotals = (partial, total) => {
    if (partial != null && total != null) {
        return `${partial}/${total}`
    }

    return "NA"
}

const RenderError = ({ errors, columns }) => {
    if (errors) {
        if (Array.isArray(errors)) {
            return <DataGrid
                rows={errors.map(e => { return { id: uuidv4(), ...e } })}
                slots={{
                    // Hide `columnMenuColumnsItem`
                    columnMenuColumnsItem: null,
                }}
                columns={columns}
                hideFooter={true}
            />
        }

        return <Paper elevation={3} sx={{ padding: 2, textAlign: "center", background: "lightgray" }}>
            {errors}
        </Paper>

    }
    return <Paper elevation={3} sx={{ padding: 2, textAlign: "center", background: "lightgray" }}>
        NA
    </Paper>
}



const renderTotalMetadata = (args, metadata, t) => {

    const columns = [
        { field: 'row_number', headerName: t('row_number'), width: "100", sortable: false, editable: false },
        {
            field: 'errors',
            headerName: t('errors'),

            sortable: false,
            editable: false,
            minWidth: '450',
            valueGetter: (value) => {
                return Object.keys(value.row.errors).map(key => `${key}: [${value.row.errors[key].join(",")}]`).join(";")

            },
        },
    ];
    const bearer = localStorage.getItem("user_token");
    if (metadata.hasOwnProperty('job_result')) {
        const jobResult = metadata.job_result
        return <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Typography>{t("job_total_inserted_rows")}: {renderTotals(jobResult.total_inserted_or_updated_rows, jobResult.total_rows)}</Typography>
            <Typography>{t("job_total_ignored_rows")}:  {renderTotals(jobResult.total_ignored_rows, jobResult.total_rows)}</Typography>
            <Typography>{t("job_total_errors")}: {renderTotals(jobResult.total_errors, jobResult.total_rows)} </Typography>
            <Box display={"flex"} minHeight={300} minWidth={600} flexDirection={'column'} >
                <RenderError errors={jobResult.errors} columns={columns} />
            </Box>
            {Array.isArray(jobResult.errors) ?
                <Link to={`${window.BASE_URL}/api/v1/import_configuration/${args.import_configuration_id}/import/${args.import_id}/export?token=${bearer}`} target="_blank" download>
                    {t('import_download_row_in_error')}
                </Link> : <></>}
        </Box>
    } else <div> {t("invalid_metadata")}</div>
}

const MetadataCell = ({ tableParams: { row: { args, metadata } }, setModalMetadata, setModalMetadataContent, setModalMetadataTitle }) => {

    const { t } = useTranslation()
    const onClickError = () => {
        setModalMetadata(true)
        setModalMetadataTitle(t("import_result"))
        setModalMetadataContent(renderTotalMetadata(args, metadata, t))
    }
    if (metadata.hasOwnProperty('job_result')) {

        const jobResult = metadata.job_result
        if (jobResult.state == "ok") {
            return <Box display={"flex"} alignItems={"center"} gap={3}>
                <CloudUploadIcon sx={{ color: "green" }} />
                <Box display={"flex"} flexDirection={"column"}>
                    <Typography>{t("job_total_inserted_rows")}: {renderTotals(jobResult.total_inserted_or_updated_rows, jobResult.total_rows)}</Typography>
                    <Typography>{t("job_total_ignored_rows")}:  {renderTotals(jobResult.total_ignored_rows, jobResult.total_rows)}</Typography>
                </Box>
            </Box>
        }

        return <Box display={"flex"} alignItems={"center"} gap={3} onClick={onClickError}>
            <CloudUploadIcon sx={{ color: "red" }} />
            <Box display={"flex"} flexDirection={"column"}>
                <Typography>{t("job_total_inserted_rows")}: {renderTotals(jobResult.total_inserted_or_updated_rows, jobResult.total_rows)}</Typography>
                <Typography>{t("job_total_ignored_rows")}:  {renderTotals(jobResult.total_ignored_rows, jobResult.total_rows)}</Typography>
                <Typography>{t("job_total_errors")}: {renderTotals(jobResult.total_errors, jobResult.total_rows)} </Typography>
            </Box>
        </Box>
    }
    return "-"
}

export default MetadataCell