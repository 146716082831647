import { Box, Typography, Button } from "@mui/material";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import MeterDatePicker from "../form/formComponents/MeterDatePicker";
import SingleDatePicker from "../form/formComponents/SingleDatePicker";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PolicyTranser from "./PolicyTranser";
import BalanceTransferList from "../../pages/balances/BalanceTransferList";
import { resetImportInfo, setImportUiid } from "../../redux/slices/importSlice";
import { actions } from "../../redux/slices/iwdWebSocketSlice";
import SearchOnServer from "../form/formComponents/SearchOnServer";
import UserWidget from "../users/UserWidget";
import Threshold from "./supply_widgets/Threshold";
import { setImportInfo } from "../../redux/slices/importSlice";
import GroupsWidet from "./supply_widgets/GroupsWidet";
import { store } from "../../redux/store";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { resetMeterIndex } from "../../redux/slices/meterSlice";
import ClosePercentage from "./supply_widgets/ClosePercentage";
import { useSelector, useDispatch } from "react-redux";
import { setProcessing } from "../../redux/slices/importSlice";
import RolesWidget from "./supply_widgets/RolesWidget";
import { setError, setErrors } from "../../redux/slices/errorSlice";
import CircularProgress from "@mui/material/CircularProgress";
import DropzoneWidget from "../worklist/worklistAqp/DropzoneWidget";
import ModalDialog from "../meters/ModalDialog";
const fullRowNames = [
  "description",
  "device_ids",
  "insertion_date",
  "group_id",
];

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (fullRowNames?.includes(element.name)) {
          return (
            <Grid key={element.id} size={12}>
              {element.content}
            </Grid>
          );
        }

        return (
          <Grid key={element.id} alignItems="center" size={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const refresh_rate_enum = [
  { const: 1 },
  { const: 2 },
  { const: 3 },
  { const: 4 },
  { const: 6 },
  { const: 8 },
  { const: 12 },
  { const: 24 },
];

const PolicyCreate = () => {
  const {
    isWSConnected,
    data,
    error,
    channels,
    cashedData,

    loading: wsLoading,
  } = useSelector((state) => state.iwdWebSocketSlice);
  const dispatch = useDispatch();
  const wsErrors = useSelector(
    (state) => state?.importSlice?.importInfo
  )?.[0]?.[0]?.after_operation_result?.error;
  const createdWsId = useSelector(
    (state) => state?.importSlice?.importInfo
  )?.[0]?.[0]?.after_operation_result?.success?.policy_id;
  const genericWsError = useSelector(
    (state) => state?.importSlice?.importInfo
  )?.[0]?.[0]?.state?.state;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [createdId, setCreatedId] = useState(null);
  const [createPolicy] = store.useCreatePolicyMutation();
  const importUuid = useSelector((state) => state?.importSlice?.importUuid);
  const [transferError, setTransferError] = useState(false);
  const [file, setFile] = useState(false);
  const [fetchErrors, setFetchErrors] = useState(null);
  const importProcessing = useSelector(
    (state) => state?.importSlice?.isProcessing
  );
  useEffect(() => {
    dispatch(setBreadCrumbs({ id: t("create") }));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      name: {
        type: "string",
        title: t("name"),
      },
      user_id: {
        type: "string",
        title: t("user"),
      },
      meter_refresh_rate: {
        type: "integer",
        title: t("meter_refresh_rate"),
        oneOf: [...refresh_rate_enum],
      },
      finally_meter_refresh_rate: {
        type: "integer",
        title: t("finally_meter_refresh_rate"),
        oneOf: [...refresh_rate_enum],
      },
      close_percentage: {
        type: "integer",
        title: t("close_percentage"),
      },
      /*   max_period: {
        type: "integer",
        title: t("max_period"),
        oneOf: [...max_period_enum],
      }, */
      consumption_threshold: {
        type: "integer",
        title: t("consumption_treshhold"),
      },
      roles: {
        type: "array",
        title: t("roles"),
        roles: true,
        multiple: true,
        url: "role",
      },
      automatic_closing: {
        type: "string",
        title: t("automatic_closing"),
        enum: ["true", "false"],
        enumNames: [t("yes"), t("no")],
        default: "true",
      },
      /*  applies_to_holidays: {
        type: "string",
        title: t("applies_to_holidays"),
        enum: ["true", "false"],
        enumNames: [t("yes"), t("no")],
        default: "true",
      }, */
      insertion_date: {
        title: t("inserted_at"),
        type: "object",
        properties: {
          from: {
            type: "string",
          },
          to: {
            type: "string",
          },
        },
      },

      description: {
        type: "string",
        title: t("description"),
      },
      device_ids: {
        type: "array",
        title: t("devices_ids"),
        error: transferError,
        setFile: setFile,
        file: file,
      },
      /*  group_id: {
        type: "number",
      }, */
    },
    required: [
      "name",
      "user_id",
      "meter_refresh_rate",
      "close_percentage",
      "consumption_threshold",
      "roles",
      "automatic_closing",
    ],
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    insertion_date: {
      "ui:field": "datepicker",
    },
    interrupted: {
      "ui:field": "SingleDatePicker",
    },
    description: {
      "ui:widget": "textarea",
    },
    roles: {
      "ui:field": "roles",
    },
    device_ids: {
      "ui:field": "transfer",
    },
    close_percentage: {
      "ui:field": "ClosePercentage",
    },
    consumption_threshold: {
      "ui:field": "Threshold",
    },
    user_id: {
      "ui:field": "user",
    },
    /*  group_id: {
      "ui:field": "groups",
    }, */
    "ui:submitButtonOptions": {
      submitText: t("create"),

      props: {
        className: "bntListSearch",
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
  };

  useEffect(() => {
    console.log("createdWsId", createdWsId);
  }, [createdWsId]);

  const fields = {
    datepicker: MeterDatePicker,
    SingleDatePicker: SingleDatePicker,
    roles: RolesWidget,
    transfer: PolicyTranser,
    user: UserWidget,
    ClosePercentage: ClosePercentage,
    Threshold: Threshold,
    groups: GroupsWidet,
  };

  useEffect(() => {
    dispatch(resetMeterIndex());
  }, []);

  useEffect(() => {
    console.log("frmdatyara", wsErrors);
  }, [wsErrors]);
  useEffect(() => {
    if (formData?.close_percentage > 100) {
      setFormData({
        ...formData,
        close_percentage: 100,
      });
    }
  }, [formData?.close_percentage]);
  const customValidate = (formData, errors) => {
    if (!formData.insertion_date.from) {
      errors?.insertion_date?.from?.addError(t("required_property"));
    }
    if (!formData.insertion_date.to) {
      errors?.insertion_date?.to?.addError(t("required_property"));
    }
    if (formData.insertion_date.from >= formData.insertion_date.to) {
      errors.insertion_date.to.addError(t("date_error"));
    }
    if (formData?.roles?.length == 0) {
      errors.roles.addError(t("required_property"));
    } else {
      /* if (formData?.device_ids?.length == 0) {
      setTransferError(true);
      errors.roles.addError(t("required_property"));
    } */
      setTransferError(false);
    }

    return errors;
  };
  const onSubmitClick = () => {
    const attributes = {
      name: formData?.name,
      start_date: formData?.insertion_date?.from,
      end_date: formData?.insertion_date?.to,
      user_id: formData?.user_id,
      meter_refresh_rate: formData?.meter_refresh_rate,
      finally_meter_refresh_rate: formData?.finally_meter_refresh_rate,
      close_percentage: formData?.close_percentage,
      device_group_id: formData?.group_id,
      max_period: formData?.max_period,
      use_digital_twin_logic: true,
      consumption_threshold: formData?.consumption_threshold,
      automatic_closing: formData?.automatic_closing === "true" ? true : false,
      roles: formData?.roles,
      device_ids: formData?.device_ids,
      description: formData?.description ?? "",
      max_period: 5,
    };

    if (file) {
      setOpen(true);
      dispatch(setProcessing(true));
      const formDataWithFile = new FormData();
      const bearer = localStorage.getItem("user_token");
      Object.keys(attributes).forEach((key) => {
        if (Array.isArray(attributes[key])) {
          attributes[key].forEach((item) => {
            formDataWithFile.append(`${key}[]`, item.toString());
          });
        } else {
          formDataWithFile.append(key, attributes[key]);
        }
      });

      formDataWithFile.append("uuid", importUuid);
      formDataWithFile.append("file", file);
      formDataWithFile.append(
        "import_configuration_id",
        window.IMPORT_CONFIGURATION_ID
      );

      fetch(`${window.BASE_URL}/api/v1/plugin/policy`, {
        method: "POST",
        body: formDataWithFile,
        headers: {
          Authorization: `Bearer ${bearer}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          /* setCreatedId(data?.data?.id);
          if (data?.data?.errors) {
            dispatch(setErrors({ error: data?.data?.errors }));
          } */
          console.log("dataepta", data);
          setFetchErrors(data?.errors);
          dispatch(setProcessing(false));
        })
        .catch((error) => {
          debugger;
          console.error("Error occurred:", error);
        });
    } else {
      createPolicy(attributes)
        .unwrap()
        .then((data) => {
          setCreatedId(data?.data?.attributes?.policy?.id);
          if (data?.data?.attributes?.errors) {
            dispatch(setErrors({ error: data?.data?.attributes?.errors }));
          }
        })
        ?.catch((error) => {
          console.error("Error occurred:", error);
        });
    }
  };

  useEffect(() => {
    if (createdId || createdWsId) {
      navigate(`/supply_policies/${createdId ?? createdWsId}`);
      dispatch(resetImportInfo());
    }
  }, [createdId, createdWsId]);

  const ErrorMessages = ({ errors, t }) => {
    if (!errors || Object.keys(errors).length === 0) {
      return null; // Если ошибок нет, компонент не рендерится
    }

    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t("import_finished_with_errors")}
        </Typography>
        <Box sx={{ textAlign: "left" }}>
          {Object.entries(errors).map(([key, values]) => (
            <Typography key={key} variant="body2" sx={{ mb: 1 }}>
              {`${t(key)}: ${t(values)}`}
            </Typography>
          ))}
        </Box>
      </Box>
    );
  };

  const onClose = () => {
    if (!importProcessing) {
      setOpen(false);
      dispatch(resetImportInfo());
      setFetchErrors(null);
      setFile(null);
      dispatch(setImportUiid(null));
      if (Object.keys(channels).length > 0) {
        dispatch(actions.cleanData("update"));
        dispatch(actions.leaveChannel("general_info"));
      }
    }
  };
  return (
    <Box>
      <ModalDialog
        open={open}
        close={onClose}
        title={" "}
        cancell={!importProcessing}
      >
        <Box display={"flex"}>
          {importProcessing && !fetchErrors && (
            <>
              <Typography>{t("data_is_importing")}</Typography>
              <CircularProgress sx={{ ml: 2 }} size={20} />
            </>
          )}
          <ErrorMessages errors={wsErrors ?? fetchErrors} t={t} />
          {genericWsError == "error" && (
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("import_failed_with_errors")}
            </Typography>
          )}
        </Box>
      </ModalDialog>
      <Form
        schema={schema}
        fields={fields}
        formData={formData}
        onChange={(changeEvent) => setFormData(changeEvent.formData)}
        onSubmit={onSubmitClick}
        validator={validator}
        uiSchema={uiSchema}
        customValidate={customValidate}
        showErrorList={false}
        noHtml5Validate
      />
    </Box>
  );
};
export default PolicyCreate;
